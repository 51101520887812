import { PeopleAltRounded, PlaylistAddCheckRounded, ToysRounded } from '@mui/icons-material'
import { Grid } from '@mui/material'

import DashboardCard from './DashboardCard'

export default function DashboardCards() {
  return (
    <Grid container direction="row" spacing={3} mt={0}>
      <Grid item xs={4}>
        <DashboardCard navigateTo="/transports" icon={<ToysRounded />} buttonText="transports" />
      </Grid>
      <Grid item xs={4}>
        <DashboardCard navigateTo="/reservations" icon={<PlaylistAddCheckRounded />} buttonText="Reservations" />
      </Grid>
      <Grid item xs={4}>
        <DashboardCard navigateTo="/freeriderusers" icon={<PeopleAltRounded />} buttonText="Freerider Users" />
      </Grid>
    </Grid>
  )
}
