export type Locale = 'EN' | 'SE' | 'NO'

export function detectLocale(): Locale {
  if (typeof window !== 'undefined') {
    const { hostname } = window.location
    if (hostname.endsWith('.se')) {
      return 'SE'
    }
    if (hostname.endsWith('.no')) {
      return 'NO'
    }
  }
  return 'EN'
}
