import {
  DashboardRounded,
  EmojiTransportationRounded,
  // Temporary removed as we will hide settings in the sidebar menu until we released alpha
  // SettingsRounded,
  ManageAccountsRounded,
  PeopleAltRounded,
  PlaylistAddCheckRounded,
  ToysRounded,
} from '@mui/icons-material'

import { NavigationItem } from '../components/core/NavigationList'

const navigationItems: NavigationItem[] = [
  {
    id: 1,
    icon: <DashboardRounded />,
    name: 'Dashboard',
    path: '/',
    subItems: [],
  },
  {
    id: 2,
    icon: <ToysRounded />,
    name: 'Transports',
    path: '/transports',
    subItems: [],
  },
  {
    id: 3,
    icon: <PlaylistAddCheckRounded />,
    name: 'Reservations',
    path: '/reservations',
    subItems: [],
  },

  {
    id: 4,
    icon: <PeopleAltRounded />,
    name: 'Freerider users',
    path: '/freeriderusers',
    subItems: [],
  },
  {
    id: 5,
    icon: <EmojiTransportationRounded />,
    name: 'Stations',
    path: '/stations',
    subItems: [],
  },
  {
    id: 6,
    icon: <ManageAccountsRounded />,
    name: 'Admin users',
    path: '/adminusers',
    subItems: [],
  },
  // Temporary removed as we will hide settings in the sidebar menu until we released alpha
  // {
  //   id: 7,
  //   icon: <SettingsRounded />,
  //   name: 'Settings',
  //   path: '/settings',
  //   subItems: [],
  // },
]

export default navigationItems
