import { Stack, Paper, Typography, styled, Box } from '@mui/material'

const InfoTextBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  '& p': {
    fontWeight: 400,
    fontsize: '16px',
    lineHeight: '24px',
    color: '#000000CC',
  },
}))

export default function DashboardText() {
  return (
    <InfoTextBox component={Paper}>
      <Stack maxWidth="xs" spacing={3}>
        <Typography>Welcome to Freerider, where your journey contributes to our journey!</Typography>
        <Typography>We&apos;re currently steering through the development highway using Agile methodology.</Typography>
        <Typography>
          This dashboard is your cockpit, where very soon, you&apos;ll be able to see statistics and insights that help
          you navigate your free rides more efficiently. Stay tuned for updates as we gear up to offer you the best car
          relocator experience. Happy driving!
        </Typography>
      </Stack>
    </InfoTextBox>
  )
}
