import { AutocompleteRenderOptionState, InputLabel, styled, Typography } from '@mui/material'

import theme from '../../styles/theme/Theme'
import { StationLocation } from '../../types/station.types'

export const StationName = styled('span')(() => ({
  fontSize: '16px',
  fontWeight: 400,
  color: '#000000',
  marginRight: theme.spacing(0.25),
}))

export const StationTracCode = styled('span')(() => ({
  fontSize: '12px',
  fontWeight: 400,
  color: '#00000099',
}))
export const Label = styled(InputLabel)(() => ({
  color: '#45454E',
  fontWeight: 500,
  fontSize: '12px',
}))

export const stationOptionStyle = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: StationLocation,
  state: AutocompleteRenderOptionState,
) => (
  <li {...props} style={{ backgroundColor: state.selected ? 'lightgray' : 'white' }}>
    <Typography>
      <StationName>{option.name}</StationName>
      <StationTracCode>{option.tracCode}</StationTracCode>
    </Typography>
  </li>
)
