import { Car, CarDetailsResponse } from './car.types'
import { Location } from './Location'
import { ReservationResponse } from './Reservation'
import { TableSettingsQuery } from './table.types'
import { AdminAccessRole, UserStatus } from './user.types'

interface UserMeta {
  id: number
  iamId: string
  firstName: string
  lastName: string
  emailAddress: string
  userStatus: UserStatus
  adminRole: AdminAccessRole | null
  numberOfFreerides: number
  cancelledFreerides: number
}

interface TransportRoute {
  id: number
  transportOfferId: number
  pickupLocation: Location
  returnLocation: Location
  priorityOrder: number
  distance: number | null
  originalDistance: number | null
  travelTime: number | null
  originalTravelTime: number | null
  latestReturn: string
  expireTime: string
}

export enum TransportStatus {
  AVAILABLE = 'AVAILABLE',
  BOOKED = 'BOOKED',
  EXPIRED = 'EXPIRED',
  LOCKED = 'LOCKED',
}

export enum TransportOfferColumnNames {
  LICENSE_NO = 'License no',
  MODEL = 'Model',
  GROUP = 'Group',
  FROM = 'From',
  PICKUP_TIME = 'Pickup time',
  TO = 'To',
  AVAILABLE_FROM = 'Available from',
  EXPIRES = 'Expires',
  STATUS = 'Status',
  CREATED_BY = 'Created by',
}

export type TransportRouteModification = {
  id: number | null
  returnLocation: Location
  priorityOrder?: number
  latestReturn: string
  availableAt?: string
}

export type TransportOffer = {
  id: number
  status: TransportStatus
  car: Car
  availableDate: string
  expireTime: string | null
  pickupLocation: Location
  transportRoutes: TransportRoute[]
  publicInformation: string
  publicDescription: string
  internalNotes: string
  createdBy: UserMeta
  createdDate: string
  updatedBy: UserMeta | null
  updatedDate: string | null
  quantity: number
  countryCode: string
  reservation?: ReservationResponse | null
}

export type TransportOfferRequest = {
  id: number | null
  carLicenseNumber?: string
  carModel?: string
  carGroup: string
  availableDate: string
  pickupLocationCode: string
  transportRoutes: TransportRouteRequest[]
  publicInformation?: string
  publicDescription?: string
  internalNotes?: string
  quantity?: number
  countryCode: string | null
}

export type TransportOffersResponse = {
  items: TransportOffer[]
  page: number
  pageSize: number
  pageCount: number
  totalSize: number
  query: TableSettingsQuery
}

interface TransportRouteRequest {
  id: number | null
  returnLocationCode: string
  priorityOrder: number
  latestReturn: string
}

export type TransportResponseError = {
  errorCode: 'LOCKED_BY_USER' | 'ID_INFO_MISSING' | 'CAR_DOES_NOT_EXIST' | 'CAR_IN_CONFLICT' | 'CAR_DUPLICATION'
  errorMessage: string
  errorEntity: CarDetailsResponse | null
}
