import { Container } from '@mui/material'

import Dashboard from '../features/dashboard/Dashboard'

export default function Home() {
  return (
    <Container maxWidth={false}>
      <Dashboard />
    </Container>
  )
}
