import './index.css'

import { MsalProvider } from '@azure/msal-react'
import { ThemeProvider } from '@mui/material'
import React, { lazy, StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Loading from './components/loading/Loading'
import AuthLayout from './features/layout/AuthLayout'
import msalInstance from './msal'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import Reservations from './pages/Reservations'
import UnderConstruction from './pages/UnderConstruction'
import store from './store/store'
import theme from './styles/theme/Theme'

const Transports = lazy(() => import('./pages/Transports'))
const FreeriderUsers = lazy(() => import('./pages/FreeriderUsers'))
const Stations = lazy(() => import('./pages/Stations'))
const AdminUsers = lazy(() => import('./pages/AdminUsers'))

const root = createRoot(document.getElementById('root')!)

root.render(
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MsalProvider instance={msalInstance}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<AuthLayout />}>
                <Route path="/" element={<Home />} />
                <Route
                  path="transports"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Transports />
                    </Suspense>
                  }
                />
                <Route
                  path="reservations"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Reservations />
                    </Suspense>
                  }
                />
                <Route
                  path="freeriderusers"
                  element={
                    <Suspense fallback={<Loading />}>
                      <FreeriderUsers />
                    </Suspense>
                  }
                />
                <Route
                  path="stations"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Stations />
                    </Suspense>
                  }
                />
                <Route
                  path="adminusers"
                  element={
                    <Suspense fallback={<Loading />}>
                      <AdminUsers />
                    </Suspense>
                  }
                />

                <Route
                  path="settings"
                  element={
                    <Suspense fallback={<Loading />}>
                      <UnderConstruction />
                    </Suspense>
                  }
                />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </MsalProvider>
      </ThemeProvider>
    </Provider>
  </StrictMode>,
)
