import { areIntervalsOverlapping, isBefore, isWithinInterval, parseISO } from 'date-fns'

import { CarBlockedPeriod, CarDetailsResponse } from '../types/car.types'
import { TracDateErrors } from '../types/errors.types'

type LatestReturn = {
  date: string
}

const checkIfBlockingDatesExists = (car: CarDetailsResponse): boolean => {
  if (
    car?.euCheck ??
    car?.expectedSalesDate ??
    car?.salesDate ??
    car?.blockedRentalAgreementDates ??
    car?.blockedReservationsDates
  ) {
    return true
  }
  return false
}

export const checkIfDateIsBeforeBlockingDate = (date: string, blockingDate: string): boolean => {
  if (isBefore(parseISO(blockingDate), parseISO(date))) {
    return false
  }
  return true
}

export const checkIfDateIsOutsideBlockingDateSpan = (date: string, blockingDates: CarBlockedPeriod[]): boolean => {
  const newDate = parseISO(date)
  let isValid = true

  blockingDates.forEach((span) => {
    const blockedSpan = { start: parseISO(span.pickupDateTime), end: parseISO(span.dueInDateTime) }

    if (isWithinInterval(newDate, { start: blockedSpan.start, end: blockedSpan.end })) {
      isValid = false
    }
  })
  return isValid
}

const checkIfDateSpanIsOutsideBlockingDateSpan = (
  availableFrom: string,
  latestReturn: string,
  blockedDateSpan: CarBlockedPeriod[],
): boolean => {
  const newSpan = { start: parseISO(availableFrom), end: parseISO(latestReturn) }
  let isValid = true

  blockedDateSpan.forEach((span) => {
    const blockedSpan = { start: parseISO(span.pickupDateTime), end: parseISO(span.dueInDateTime) }

    if (newSpan.start < newSpan.end) {
      if (
        areIntervalsOverlapping(
          { start: newSpan.start, end: newSpan.end },
          { start: blockedSpan.start, end: blockedSpan.end },
        )
      ) {
        isValid = false
      }
    } else {
      const availableFromIsValid = checkIfDateIsOutsideBlockingDateSpan(availableFrom, blockedDateSpan)
      const latestReturnIsValid = checkIfDateIsOutsideBlockingDateSpan(latestReturn, blockedDateSpan)

      if (availableFromIsValid && latestReturnIsValid) {
        isValid = true
      } else {
        isValid = false
      }
    }
  })
  return isValid
}

const getEuCheckError = (blockingDate: string | null, date: string): string | null => {
  if (!blockingDate) return null

  if (!checkIfDateIsBeforeBlockingDate(date, blockingDate)) {
    return `Car will have an EU check (${blockingDate.split('T')[0]}) during the interval selected.`
  }
  return null
}

const getExpectedSalesError = (blockingDate: string | null, date: string): string | null => {
  if (!blockingDate) return null

  if (!checkIfDateIsBeforeBlockingDate(date, blockingDate)) {
    return `Car has a expected sales date (${blockingDate.split('T')[0]}) within the interval selected.`
  }
  return null
}

const getSalesError = (blockingDate: string | null, date: string): string | null => {
  if (!blockingDate) return null

  if (!checkIfDateIsBeforeBlockingDate(date, blockingDate)) {
    return `Car has a sales date (${blockingDate.split('T')[0]}) within the interval selected.`
  }
  return null
}

const getRentalError = (
  blockingDates: CarBlockedPeriod[] | null,
  availableFrom: string,
  latestReturn: string | undefined,
): string | null => {
  if (!blockingDates) return null
  let hasError = false

  if (latestReturn) {
    if (!checkIfDateSpanIsOutsideBlockingDateSpan(availableFrom, latestReturn, blockingDates)) {
      hasError = true
    }
  } else {
    if (!checkIfDateIsOutsideBlockingDateSpan(availableFrom, blockingDates)) {
      hasError = true
    }
  }

  if (hasError) {
    return 'Car has a rental agreement during the interval selected'
  }
  return null
}

const getReservationError = (
  blockingDates: CarBlockedPeriod[] | null,
  availableFrom: string,
  latestReturn: string | undefined,
): string | null => {
  if (!blockingDates) return null
  let hasError = false

  if (latestReturn) {
    if (!checkIfDateSpanIsOutsideBlockingDateSpan(availableFrom, latestReturn, blockingDates)) {
      hasError = true
    }
  } else {
    if (!checkIfDateIsOutsideBlockingDateSpan(availableFrom, blockingDates)) {
      hasError = true
    }
  }

  if (hasError) {
    return 'Car has a reservation during the interval selected'
  }
  return null
}

export const getLicenseNumberError = (licenseNumber?: string, hasActiveOffer?: boolean): string | null => {
  if (licenseNumber && hasActiveOffer) {
    return `Car with license number ${licenseNumber} has an active transport offer`
  }
  return null
}

export const getFromStationError = (tracCode: string, existingTracCode?: string): string | null => {
  if (existingTracCode && existingTracCode !== tracCode) {
    return `Car is not checked in at the current selected from station. Currently checked in at ${existingTracCode}.`
  }
  return null
}

export const getAvailableFromErrors = (
  car: CarDetailsResponse,
  availableFrom: string,
  latestReturn: LatestReturn[] | undefined,
): TracDateErrors | null => {
  const errors: TracDateErrors = {
    euCheck: null,
    expectedSales: null,
    sales: null,
    rental: null,
    reservation: null,
  }

  if (checkIfBlockingDatesExists(car)) {
    errors.euCheck = getEuCheckError(car.euCheck, availableFrom)
    errors.expectedSales = getExpectedSalesError(car.expectedSalesDate, availableFrom)
    errors.sales = getSalesError(car.salesDate, availableFrom)

    if (!latestReturn) {
      errors.rental = getRentalError(car.blockedRentalAgreementDates, availableFrom, latestReturn)
      errors.reservation = getReservationError(car.blockedReservationsDates, availableFrom, latestReturn)
    } else {
      latestReturn.forEach((returnDate) => {
        const rentalError = getRentalError(car.blockedRentalAgreementDates, availableFrom, returnDate.date)
        const reservationError = getReservationError(car.blockedReservationsDates, availableFrom, returnDate.date)

        if (reservationError) {
          errors.reservation = reservationError
        }
        if (rentalError) {
          errors.rental = rentalError
        }
      })
    }
  }

  const hasErrors = Object.values(errors).some((error) => error !== null)
  return hasErrors ? errors : null
}

export const getLatestReturnErrors = (
  car: CarDetailsResponse,
  availableFrom: string,
  latestReturn: LatestReturn[] | undefined,
): TracDateErrors | null => {
  if (!latestReturn) return null

  const errors: TracDateErrors = {
    euCheck: null,
    expectedSales: null,
    sales: null,
    rental: null,
    reservation: null,
  }

  if (checkIfBlockingDatesExists(car)) {
    latestReturn.forEach((returnDate) => {
      const euCheckError = getEuCheckError(car.euCheck, returnDate.date)
      const expectedSalesError = getExpectedSalesError(car.expectedSalesDate, returnDate.date)
      const salesError = getSalesError(car.salesDate, returnDate.date)
      const rentalError = getRentalError(car.blockedRentalAgreementDates, availableFrom, returnDate.date)
      const reservationError = getReservationError(car.blockedReservationsDates, availableFrom, returnDate.date)

      if (euCheckError) {
        errors.euCheck = euCheckError
      }
      if (expectedSalesError) {
        errors.expectedSales = expectedSalesError
      }
      if (salesError) {
        errors.sales = salesError
      }
      if (rentalError) {
        errors.rental = rentalError
      }
      if (reservationError) {
        errors.reservation = reservationError
      }
    })
  }

  const hasErrors = Object.values(errors).some((error) => error !== null)
  return hasErrors ? errors : null
}
