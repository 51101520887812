import axios, { isAxiosError } from 'axios'

import { TableDataRequest } from '../types/table.types'
import {
  TransportResponseError,
  TransportOffer,
  TransportOfferRequest,
  TransportOffersResponse,
} from '../types/transport.types'
import { getResponseError } from '../utils'

const transportOffersEndpoint = process.env.REACT_APP_ENDPOINT_TRANSPORTOFFERS

export const getTransportOfferById = async (id: number): Promise<TransportOffer | null> => {
  try {
    const response = await axios.get<TransportOffer>(`${transportOffersEndpoint}${id}`)
    return response.data
  } catch (error) {
    return null
  }
}

export const getTransportOffers = async (data: TableDataRequest) => {
  const response = await axios.get<TransportOffersResponse>(`${transportOffersEndpoint}search`, { params: data })
  return response
}

export const createTransportOffer = async (
  transport: TransportOfferRequest,
): Promise<boolean | TransportResponseError> => {
  try {
    await axios.post(transportOffersEndpoint, transport)
    return true
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const errorResponse = getResponseError(error.response.data)
      if (errorResponse) {
        return errorResponse
      }
      return false
    }
    return false
  }
}

export const updateTransportOffer = async (transport: TransportOfferRequest): Promise<boolean> => {
  try {
    await axios.put(transportOffersEndpoint, transport)
    return true
  } catch (error) {
    return false
  }
}

// purposely left as only a default export as we move away from components directly fetching from the API without using a thunk
const deleteTransportOffers = async (transports: number[]): Promise<boolean | TransportResponseError> => {
  try {
    await axios.delete(transportOffersEndpoint, { data: transports })
    return true
  } catch (error) {
    if (isAxiosError(error) && error.response) {
      const errorResponse = getResponseError(error.response.data)
      if (errorResponse) {
        return errorResponse
      }
      return false
    }
    return false
  }
}

export default {
  createTransportOffer,
  deleteTransportOffers,
  getTransportOfferById,
  getTransportOffers,
  updateTransportOffer,
}
