import { Button } from '@mui/material'

type ButtonProps = {
  label: string | JSX.Element
  size?: 'small' | 'medium' | 'large'
  isDisabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
}

function FRButton(props: ButtonProps) {
  const { size, isDisabled, className, onClick, label } = props

  return (
    <Button disabled={isDisabled} className={className} onClick={onClick} size={size} variant="contained">
      {label}
    </Button>
  )
}

FRButton.defaultProps = { size: 'medium', isDisabled: false, className: '', onClick: undefined }
export default FRButton
