import { Box, TextField } from '@mui/material'
import { debounce } from 'lodash'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'

import Icon from '../../../assets/search-icon.svg'

type Props = {
  initialValue?: string
  onChange: (value: string) => void
}

export default function TableSearchField({ initialValue = '', onChange }: Props) {
  const [searchValue, setSearchValue] = useState(initialValue)

  const handleDebounceChange = useCallback(
    (value: string) => {
      onChange(value)
    },
    [onChange],
  )

  const debouncedSearchValue = useMemo(
    () => debounce((value: string) => handleDebounceChange(value), 300),
    [handleDebounceChange],
  )

  useEffect(
    () => () => {
      debouncedSearchValue.cancel()
    },
    [debouncedSearchValue],
  )

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(event.target.value)
    debouncedSearchValue(event.target.value)
  }

  return (
    <Box>
      <img src={Icon} alt="Search icon" style={{ marginRight: 16, marginTop: 8 }} />
      <TextField
        value={searchValue}
        size="small"
        margin="dense"
        type="text"
        placeholder="Search for..."
        sx={{ top: -5 }}
        variant="standard"
        required={false}
        onChange={handleInputChange}
        fullWidth={false}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  )
}
