import { styled } from '@mui/material'
import { PropsWithChildren } from 'react'

export const StyledCell = styled('div')(() => ({
  color: '#000000',
  fontSize: '14px',
}))

type Props = {
  firstLine?: string
  secondLine?: string
  blueText?: boolean
  capitalized?: boolean
}

function TableCell({ firstLine, secondLine, blueText, capitalized, children }: PropsWithChildren<Props>) {
  return (
    <StyledCell
      data-tag="allowRowEvents"
      {...(blueText && {
        style: { color: '#3A81B3', fontWeight: '600' },
      })}
      {...(capitalized && {
        style: { textTransform: 'capitalize' },
      })}
    >
      {firstLine || ''}
      {secondLine && <StyledCell style={{ color: '#00000099', fontSize: '12px' }}>{secondLine || ''}</StyledCell>}
      {children}
    </StyledCell>
  )
}

export default TableCell
