import { Middleware } from '@reduxjs/toolkit'

import {
  TableSettings as TableVariantSettings,
  TableSettingsState,
  initialTableSettings,
} from '../../features/table/tableSettingsSlice'
import { TableSettings } from '../../types/table.types'
import { AdminsState, initialState as adminsState } from '../slices/adminsSlice'
import { ReservationsState, initialState as reservationsState } from '../slices/reservationsSlice'
import { TransportOffersState, initialState as transportOffersState } from '../slices/transportOfferSlice'
import { UsersState, initialState as usersState } from '../slices/usersSlice'

export const setLocalStorageMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action)

  if (action.type.startsWith('tableSettings/')) {
    const { tableVariant } = action.payload
    const tableKey = `${tableVariant}Table`
    const tableSettings = store.getState().tableSettings[tableVariant]
    localStorage.setItem(tableKey, JSON.stringify(tableSettings))
  }

  if (action.type.startsWith('admins/')) {
    const { tableSettings } = store.getState().admins
    localStorage.setItem('adminsTable', JSON.stringify(tableSettings))
  }

  if (action.type.startsWith('reservations/')) {
    const { tableSettings } = store.getState().reservations
    localStorage.setItem('reservationsTable', JSON.stringify(tableSettings))
  }
  if (action.type.startsWith('transportOffers/')) {
    const { tableSettings } = store.getState().transportOffers
    localStorage.setItem('transportOffersTable', JSON.stringify(tableSettings))
  }
  if (action.type.startsWith('users/')) {
    const { tableSettings } = store.getState().users
    localStorage.setItem('freeriderUsersTable', JSON.stringify(tableSettings))
  }
  return result
}

const getTableVariantSettings = (key: string, defaultSettings: TableVariantSettings): TableVariantSettings => {
  const serializedState = localStorage.getItem(key) || '{}'
  const parsedState = JSON.parse(serializedState)

  return { ...defaultSettings, ...parsedState }
}

const getTableSettings = (
  defaultSettings: TableSettings,
  key: 'freeriderUsersTable' | 'transportOffersTable' | 'reservationsTable' | 'adminsTable',
): TableSettings => {
  const serializedState = localStorage.getItem(key) || '{}'
  const parsedState = JSON.parse(serializedState)

  return { ...defaultSettings, ...parsedState }
}

export const getTableSettingsStateFromLocalStorage = (): TableSettingsState => ({
  stations: getTableVariantSettings('stationsTable', initialTableSettings),
  adminUsers: getTableVariantSettings('adminUsersTable', initialTableSettings),
})

export const getAdminsStateFromLocalStorage = (): AdminsState => ({
  admins: adminsState.admins,
  loadingAdmins: adminsState.loadingAdmins,
  tableSettings: getTableSettings(adminsState.tableSettings, 'adminsTable'),
})

export const getReservationsStateFromLocalStorage = (): ReservationsState => ({
  reservations: reservationsState.reservations,
  loadingReservations: reservationsState.loadingReservations,
  tableSettings: getTableSettings(reservationsState.tableSettings, 'reservationsTable'),
})

export const getTransportOffersStateFromLocalStorage = (): TransportOffersState => ({
  transportOffers: transportOffersState.transportOffers,
  loadingTransportOffers: transportOffersState.loadingTransportOffers,
  tableSettings: getTableSettings(transportOffersState.tableSettings, 'transportOffersTable'),
})

export const getUsersStateFromLocalStorage = (): UsersState => ({
  users: usersState.users,
  loadingUsers: usersState.loadingUsers,
  tableSettings: getTableSettings(usersState.tableSettings, 'freeriderUsersTable'),
})
