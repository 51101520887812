import { Box } from '@mui/material'
import { useCallback } from 'react'

import ColumnChoser from './ColumnChoser'
import FilterButtons from './FilterButtons'
import { FilterItem } from '../../../types/table.types'

type Props = {
  columnNames: string[]
  filterItems: FilterItem[]
  filter: string
  hiddenColumns: string[]
  onFilterChange: (value: string) => void
  onCheckedColumnsChange: (value: string[]) => void
}

export default function TableToolBar({
  columnNames,
  filterItems,
  filter,
  hiddenColumns,
  onFilterChange,
  onCheckedColumnsChange,
}: Props) {
  const getHiddenColumns = useCallback(
    (unchecked: string[]) => {
      onCheckedColumnsChange(unchecked)
    },
    [onCheckedColumnsChange],
  )

  return (
    <Box display="inline-flex">
      <FilterButtons defaultFilter={filter} filterItems={filterItems} onFilterChange={onFilterChange} />
      <ColumnChoser
        resetButtonLabel="Reset to default"
        buttonLabel="Columns"
        columnNames={columnNames}
        getUnCheckedList={getHiddenColumns}
        uncheckedColumns={hiddenColumns}
      />
    </Box>
  )
}
