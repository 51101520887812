import { Car } from './car.types'
import { Location } from './Location'
import { TableSettingsQuery } from './table.types'
import { AdminAccessRole, User, UserStatus } from './user.types'

interface Driver {
  address: string
  adminRole: AdminAccessRole | null
  cancelledFreerides: number
  city: string
  countryCode: string
  emailAddress: string
  firstName: string
  iamId: string
  id: number
  lastName: string
  memberSince: string
  numberOfFreerides: number
  phoneNumber: string
  postalCode: string
  secondAddress: string
  socialSecurityNumber: string
  userStatus: UserStatus
}

export enum ReservationColumnNames {
  RES_NO = 'Res no',
  FROM = 'From',
  PICKUP_TIME = 'Pickup time',
  CUSTOMER = 'Customer',
  LICENCE_NO = 'Licence no',
  CREATED = 'Created',
  STATUS = 'Status',
  RETURN_TIME = 'Return time',
}

export type Reservation = {
  id: number
  userId: number
  reservationId: string
  transportOfferId: number
  driver: User
  car: Car
  pickupLocation: Location
  returnLocation: Location
  pickupDate: string
  returnDate: string
  status: string
  createdDate: string
  updatedDate: string
  countryCode: string
  reservationStatus?: string
}

export type ReservationResponse = {
  car: Car
  userId: number
  countryCode: string
  createdDate: string
  driver: Driver
  pickupDate: string
  pickupLocation: Location
  publicDescription: string
  publicInformation: string
  reservationId: string
  returnDate: string
  returnLocation: Location
  status: string
  transportOfferId: number
  updatedDate: string | null
}

// FIX - CJ-2901 - rename file reservation.types to match naming conventions
// FIX - CJ-2901 - make sure type match with response
export type ReservationsResponse = {
  items: Reservation[]
  page: number
  pageSize: number
  pageCount: number
  totalSize: number
  query: TableSettingsQuery
}
