import { AccountCircle } from '@mui/icons-material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuIcon from '@mui/icons-material/Menu'
import { Menu, MenuItem, styled } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { memo, MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import LogoSrc from '../../assets/logo.svg'
import NavigationItems from '../../models/NavigationItems'
import { RootState } from '../../store/store'
import theme from '../../styles/theme/Theme'

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(() => ({
  zIndex: 100,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}))

const LogoImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'open',
})(() => ({
  width: '90px',
  marginRight: theme.spacing(2.5),
  cursor: 'pointer',
}))

type HeaderProps = {
  sideMenuOpen: boolean
  toggleSideMenuOpen: () => void
  handleLogout: () => void
}

const Header = memo(({ sideMenuOpen, toggleSideMenuOpen, handleLogout }: HeaderProps) => {
  const { user } = useSelector((state: RootState) => state.auth)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const navigate = useNavigate()

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar position="fixed" open={sideMenuOpen} color="inherit" elevation={0} variant="outlined">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleSideMenuOpen}
          edge="start"
          sx={{
            marginRight: 5,
          }}
        >
          {!sideMenuOpen && <MenuIcon />}
          {sideMenuOpen && <ChevronLeftIcon />}
        </IconButton>

        <div style={{ display: 'flex', flexGrow: 1 }}>
          <LogoImage src={LogoSrc} alt="" onClick={() => navigate(NavigationItems[0].path)} />
          <Typography variant="h5" noWrap>
            Freerider Administration
          </Typography>
        </div>

        <div>
          <IconButton size="large" onClick={handleMenu}>
            <AccountCircle
              style={{
                color: theme.palette.common.darkGray,
                marginRight: theme.spacing(1.25),
              }}
            />
            <Typography variant="button" noWrap>
              {user ? `${user.firstName} ${user.lastName}` : 'User'}
            </Typography>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleLogout()
                handleClose()
              }}
            >
              Sign Out
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  )
})

Header.displayName = 'Header'

export default Header
