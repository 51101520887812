import axios from 'axios'

import { ReservationsResponse } from '../types/Reservation'
import { TableDataRequest } from '../types/table.types'

const reservationsEndpoint = process.env.REACT_APP_ENDPOINT_RESERVATIONS

export const getReservations = async (data: TableDataRequest) => {
  const response = await axios.get<ReservationsResponse>(`${reservationsEndpoint}search`, { params: data })
  return response
}

export const cancelReservationById = async (id: string) => {
  try {
    await axios.delete(`${reservationsEndpoint}${id}`)
    return true
  } catch (error) {
    return false
  }
}
