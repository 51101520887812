import { createAsyncThunk, createSlice, Slice } from '@reduxjs/toolkit'

import { getCurrentUser } from '../../api/auth'
import { User } from '../../types/user.types'

interface AuthState {
  user: User | null
  loadingAuth: 'idle' | 'pending' | 'succeeded' | 'failed'
}

const initialState: AuthState = {
  user: null,
  loadingAuth: 'idle',
}

export const getLoggedInUser = createAsyncThunk('currentUser/getUser', async () => {
  try {
    const response = await getCurrentUser()
    return response
  } catch (error) {
    throw Error('Failed to get current user.')
  }
})

const authSlice: Slice<AuthState> = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLoggedInUser.pending, (state) => {
        state.loadingAuth = 'pending'
      })
      .addCase(getLoggedInUser.fulfilled, (state, action) => {
        state.user = action.payload
        state.loadingAuth = 'succeeded'
      })
      .addCase(getLoggedInUser.rejected, (state, action) => {
        console.error(action.payload)
        state.loadingAuth = 'failed'
      })
  },
})

export default authSlice.reducer
