import { TableColumn } from 'react-data-table-component'

import TableCell from './components/TableCell'
import { Reservation, ReservationColumnNames } from '../types/Reservation'
import { ReservationStatus } from '../types/ReservationStatus'
import { getDatePlusOffset } from '../utils'

enum StatusTexts {
  BOOKED = 'Booked',
  BOOKED_PASSED = 'Booked (Passed)',
  CANCELLED_BY_CUSTOMER = 'Cancelled by user',
  CANCELLED_BY_ADMIN = 'Cancelled by admin',
}

const getStatusText = (status: string) => {
  switch (status.toUpperCase()) {
    case ReservationStatus.CREATED:
      return StatusTexts.BOOKED
    case ReservationStatus.PASSED:
      return StatusTexts.BOOKED_PASSED
    case ReservationStatus.CANCELLED_BY_CUSTOMER:
      return StatusTexts.CANCELLED_BY_CUSTOMER
    case ReservationStatus.CANCELLED_BY_ADMIN:
      return StatusTexts.CANCELLED_BY_ADMIN
    default:
      return status.toLowerCase()
  }
}

const reservationColumns: TableColumn<Reservation>[] = [
  {
    name: ReservationColumnNames.RES_NO,
    selector: (row) => row.reservationId ?? '',
    cell: (row) => <TableCell firstLine={row.reservationId ?? ''} blueText />,
    sortable: true,
  },

  {
    name: ReservationColumnNames.FROM,
    selector: (row) => `${row.pickupLocation?.name ?? ''} ${row.pickupLocation?.tracCode ?? ''}`,
    cell: (row) => (
      <TableCell firstLine={row.pickupLocation?.name ?? ''} secondLine={row.pickupLocation?.tracCode ?? ''} />
    ),
    sortable: true,
  },
  {
    name: ReservationColumnNames.PICKUP_TIME,
    selector: (row) => row.pickupDate ?? '',
    cell: (row) => (
      <TableCell firstLine={row.pickupDate ? getDatePlusOffset(row.pickupDate).toFormat("yyyy-MM-dd' 'HH:mm") : ''} />
    ),
    sortable: true,
  },
  {
    name: ReservationColumnNames.CUSTOMER,
    selector: (row) => `${row.driver?.firstName ?? ''} ${row.driver?.lastName ?? ''}`,
    cell: (row) => (
      <TableCell
        firstLine={
          row.driver?.firstName && row.driver?.lastName ? `${row.driver?.firstName} ${row.driver?.lastName}` : ''
        }
      />
    ),
    sortable: true,
  },
  {
    name: ReservationColumnNames.LICENCE_NO,
    selector: (row) => row.car?.licenseNumber ?? '',
    cell: (row) => <TableCell firstLine={row.car?.licenseNumber ? row.car?.licenseNumber.toUpperCase() : ''} />,
    sortable: true,
  },
  {
    name: ReservationColumnNames.STATUS,
    selector: (row) => row.status ?? '',
    sortable: true,
    cell: (row) => <TableCell firstLine={getStatusText(row.status)} />,
  },
  {
    name: ReservationColumnNames.CREATED,
    selector: (row) => row.createdDate ?? '',
    cell: (row) => (
      <TableCell firstLine={row.createdDate ? getDatePlusOffset(row.createdDate).toFormat("yyyy-MM-dd' 'HH:mm") : ''} />
    ),
    sortable: true,
  },
  {
    name: ReservationColumnNames.RETURN_TIME,
    selector: (row) => row.returnDate ?? '',
    cell: (row) => (
      <TableCell firstLine={row.returnDate ? getDatePlusOffset(row.returnDate).toFormat("yyyy-MM-dd' 'HH:mm") : ''} />
    ),
    sortable: true,
  },
]

export default reservationColumns
