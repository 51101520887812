import { FormHelperText, FormLabel, styled, TextField } from '@mui/material'

export const CountryPickerTextField = styled(TextField)(({ theme, error }) => ({
  background: theme.palette.common.white,
  ...(error && {
    '&.Mui-disabled': {
      background: theme.palette.grey[100],
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  }),
  color: theme.palette.common.black,
  '&.Mui-disabled': {
    WebkitTextFillColor: theme.palette.grey[700],
  },
  '& .MuiOutlinedInput-root': {
    minHeight: '40px',
  },
}))

export const Label = styled(FormLabel)(() => ({
  color: '#45454E',
  fontWeight: 500,
  fontSize: '12px',
}))

export const CountryPickerFormHelperText = styled(FormHelperText)(() => ({
  '&.Mui-error': {
    color: '#CD2026',
  },
}))
