import { styled } from '@mui/material'

import Colors from '../../styles/colors/Colors'

const LoadingWrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const LoadingWheel = styled('div')(() => ({
  border: `8px solid ${Colors.loadingBorderColor}`,
  borderRadius: '50%',
  borderTop: `8px solid ${Colors.loadingColor}`,
  width: '60px',
  height: '60px',
  animation: 'spin 0.75s linear infinite',

  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}))

export default function Loading() {
  return (
    <LoadingWrapper>
      <LoadingWheel />
    </LoadingWrapper>
  )
}
