import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Locale } from './detectLocale'

export interface LocaleState {
  locale: Locale
}

const initialState: LocaleState = { locale: 'EN' }

const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<Locale>) => {
      state.locale = action.payload
    },
  },
})

export const { setLocale } = localeSlice.actions

export default localeSlice.reducer
