import { ReactNode } from 'react'
import { TableColumn } from 'react-data-table-component'

import { AnyColumn } from '../../../types/table.types'

export const getColumn = (name: ReactNode, columnMap: AnyColumn): string => {
  if (typeof name === 'string') {
    return columnMap[name]
  }
  return ''
}

export const getColumnNames = <T>(columns: TableColumn<T>[]) =>
  columns
    .map((column) => column.name)
    .filter((name): name is string => typeof name === 'string')
    .sort()

export const getReOrderedColumns = <T>(columns: TableColumn<T>[], columnOrder: string[]) => {
  if (columnOrder.length === 0) {
    return columns
  }

  const columnOrderMapping: Record<string, number> = {}
  columnOrder.forEach((name: string, index: number) => {
    columnOrderMapping[name] = index
  })

  const sortedColumns = [...columns].sort((a, b) => {
    const orderA = columnOrderMapping[a.name as string]
    const orderB = columnOrderMapping[b.name as string]
    return orderA - orderB
  })

  return sortedColumns
}

export const checkIfColumnsAreEqual = (oldColumns: string[], newColumns: string[]) => {
  const sortedOldColumns = [...oldColumns].sort()
  const sortedNewColumns = [...newColumns].sort()

  if (
    oldColumns.length === newColumns.length &&
    sortedOldColumns.every((value, index) => value === sortedNewColumns[index])
  ) {
    return true
  }
  return false
}
