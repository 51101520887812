import { Autocomplete, Box, FormControl, TextFieldProps, useTheme } from '@mui/material'
import React, { useState } from 'react'

import defaultCountries from './assets/defaultCountrys'
import { CountryPickerFormHelperText, Label, CountryPickerTextField } from './CountryPicker.styles'

export type CountryPickerOption = {
  code: string
  label: string
}

type CountryPickerProps = {
  label?: string
  countries?: CountryPickerOption[]
  initialCountryCode?: string
  required?: boolean
  error?: boolean
  helperText?: string
  preferredCountries?: string[]
  onChange: (value?: string) => void
}

export default function CountryPicker({
  label,
  countries = defaultCountries,
  initialCountryCode,
  helperText,
  preferredCountries,
  required,
  error,
  onChange,
}: CountryPickerProps & Pick<TextFieldProps, 'margin'>) {
  const [currentCountryCode, setCurrentCountryCode] = useState<string | undefined>(initialCountryCode)
  const theme = useTheme()

  const getCountryFromCode = () => countries.find((x) => x.code.toLowerCase() === initialCountryCode?.toLowerCase())

  const sortedCountires = () => {
    let sortedList: CountryPickerOption[] = []
    if (preferredCountries) {
      countries.forEach((element) => {
        if (preferredCountries.find((x) => x.toLowerCase() === element.code.toLowerCase())) {
          sortedList.push(element)
        }
      })
      countries.forEach((element) => {
        if (!preferredCountries.find((x) => x.toLowerCase() === element.code.toLowerCase())) {
          sortedList.push(element)
        }
      })
    } else {
      sortedList = countries
    }
    return sortedList
  }

  const codeExistInCountryList = () => countries.find((x) => x.code.toLowerCase() === currentCountryCode?.toLowerCase())

  const renderFlagIcon = (countryCode?: string) => {
    if (countryCode) {
      return (
        <img
          loading="lazy"
          width="25"
          src={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`}
          alt={`${countryCode} flag`}
        />
      )
    }
    return null
  }

  return (
    <FormControl error={error} sx={{ width: '100%', marginTop: theme.spacing(0.5) }}>
      <Label>{label}</Label>
      <Autocomplete
        defaultValue={getCountryFromCode()}
        options={sortedCountires()}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        autoHighlight
        getOptionLabel={(option) => option.label}
        onChange={(_event, newValue) => {
          onChange(newValue?.code)
          setCurrentCountryCode(newValue?.code)
        }}
        sx={{
          backgroundColor: 'white',
          minHeight: '40px',
        }}
        renderOption={(props, option) => (
          <Box
            id={option.code}
            component="li"
            sx={{
              minHeight: '40px',
              color: '#000000',
              '& > img': { mr: theme.spacing(1.5), ml: theme.spacing(0.5), flexShrink: 0 },
              ...(option.code.toLowerCase() === preferredCountries?.sort().at(-1)?.toLowerCase() && {}),
              paddingTop: theme.spacing(0, 2, 0, 2),
            }}
            {...props}
          >
            {renderFlagIcon(option.code)}
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <div style={{ position: 'relative' }}>
            {params.inputProps.value && (
              <span
                style={{
                  position: 'absolute',
                  top: '50%',
                  display: 'flex',
                  transform: 'translate(0, -50%)',
                  zIndex: 1,
                  marginLeft: theme.spacing(2.25),
                }}
              >
                {codeExistInCountryList() && renderFlagIcon(currentCountryCode)}
              </span>
            )}
            <CountryPickerTextField
              {...params}
              required={required}
              error={(required && !currentCountryCode) || error}
              inputProps={{
                ...params.inputProps,
                'aria-label': label,
                style: {
                  position: 'absolute',
                  top: '50%',
                  display: 'flex',
                  transform: 'translate(0, -50%)',
                  zIndex: 1,
                  marginLeft: theme.spacing(5.25),
                  width: '85%',
                },
              }}
            />
          </div>
        )}
      />
      {((required && !currentCountryCode) || error) && (
        <CountryPickerFormHelperText id="country-helper-text">{helperText}</CountryPickerFormHelperText>
      )}
    </FormControl>
  )
}
