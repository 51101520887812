import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getAdmins } from '../../api/user'
import { TableLoadingState, TableSettings } from '../../types/table.types'
import { User } from '../../types/user.types'
import mapTableSettings from '../../utils/mapTableSettings'

export interface AdminsState {
  admins: User[] | null
  loadingAdmins: TableLoadingState
  tableSettings: TableSettings
}

export const initialState: AdminsState = {
  admins: null,
  loadingAdmins: 'idle',
  tableSettings: {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    totalSize: undefined,
    columnOrder: [],
    hiddenColumns: [],
    statusFilter: '',
    freeText: '',
    sortBy: '',
    sortDirection: 'asc',
  },
}

export const fetchAdmins = createAsyncThunk('admins/fetchAdmins', async (settings: TableSettings, thunkAPI) => {
  try {
    const response = await getAdmins(mapTableSettings(settings))
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const reloadAdmins = createAsyncThunk<void, void, { state: { admins: AdminsState } }>(
  'admins/reloadAdmins',
  async (_: void, { getState, dispatch }) => {
    const { admins } = getState()
    await dispatch(fetchAdmins(admins.tableSettings))
  },
)

const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    setLoadingAdmins: (state, action: PayloadAction<TableLoadingState>) => {
      state.loadingAdmins = action.payload
    },
    setAdminsColumnOrder: (state, action: PayloadAction<string[]>) => {
      state.tableSettings.columnOrder = action.payload
    },
    setAdminsHiddenColumns: (state, action: PayloadAction<string[]>) => {
      state.tableSettings.hiddenColumns = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdmins.pending, (state) => {
        state.loadingAdmins = 'pending'
      })
      .addCase(fetchAdmins.fulfilled, (state, action) => {
        const { items, query, ...rest } = action.payload

        state.admins = items
        state.tableSettings = {
          ...state.tableSettings,
          statusFilter: query.statusFilter ?? '',
          freeText: query.freeText,
          sortBy: query.sortBy,
          sortDirection: query.sortDirection === 'ASC' ? 'asc' : 'desc',
          ...rest,
        }
        state.loadingAdmins = 'succeeded'
      })
      .addCase(fetchAdmins.rejected, (state) => {
        state.loadingAdmins = 'failed'
      })
  },
})

export const { setLoadingAdmins, setAdminsColumnOrder, setAdminsHiddenColumns } = adminsSlice.actions

export default adminsSlice.reducer
