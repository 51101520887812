import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ActionStatus, UserMisconductRequest } from './api/abuse.types'

interface AbuseDialogStateType {
  isOpen: boolean
  abuseDialogTitle: string
  selectedUser: UserMisconductRequest
}

const initialState: AbuseDialogStateType = {
  isOpen: false,
  abuseDialogTitle: '',
  selectedUser: {
    userId: 0,
    status: ActionStatus.NO_STATUS,
    reasonCode: null,
    referenceNumber: '',
  },
}

export const abuseDialogSlice = createSlice({
  name: 'abuseDialog',
  initialState,
  reducers: {
    showAbuseDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    setAbuseDialogTitle: (state, action: PayloadAction<string>) => {
      state.abuseDialogTitle = action.payload
    },

    setMisconductRequestPayload: (
      state,
      action: PayloadAction<{
        key: keyof UserMisconductRequest
        value: UserMisconductRequest[keyof UserMisconductRequest]
      }>,
    ) => {
      const { key, value } = action.payload
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          [key]: value,
        },
      }
    },

    resetMisconductState: (state) => {
      state.selectedUser = initialState.selectedUser
    },
  },
})

export const { showAbuseDialog, setAbuseDialogTitle, setMisconductRequestPayload, resetMisconductState } =
  abuseDialogSlice.actions

export default abuseDialogSlice.reducer
