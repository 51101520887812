const Colors = {
  btnPrimaryBgColor: '#FFCC00',
  btnPrimaryHoverColor: '#ffdb4c',
  btnPrimaryFontColor: '#000000',
  black: '#000000',

  btnSecondaryBgColor: '#4A90E2',
  btnSecondaryHoverColor: '#6fafff',
  btnSecondaryFontColor: '#ffffff',

  btnTertiaryBgColor: '#FAFAFA',
  btnTertiaryHoverColor: '#9a9999',
  btnTertiaryBorderColor: '#666666',
  btnTertiaryFontColor: '#666666',
  btnTertiaryFontHoverColor: '#ffffff',

  primaryBorderColor: '#DFDACC',
  buttonBackgroundColor: '#3A81B3',
  buttonFontColor: '#FFFFFF',
  chipAvailableBackgroundColor: '#BDBDBD', // Grey/400
  chipBookedBackgroundColor: '#689F38', // Light Green/700
  chipExpiredBackgroundColor: '#D32F2F', // Light/Error/Main
  chipPrimaryFontColor: '#000000',
  chipSecondaryFontColor: '#FFFFFF',
  linkColorPrimary: '#3F8CCC',
  linkColorSecondary: '#535353',
  navBackgroundColor: '#EEEBE0',
  navLinkColor: 'rgba(0, 0, 0, 0.3)',
  loadingBorderColor: '#CCCCCC',
  loadingColor: '#3A81B3',
  headerBackgroundColor: '#FFFFFF',
  headerFontColor: '#000000',
  mainContentBackgroundColor: '#fafafa',

  darkGray: '#858585',
  lightGrayish: '#EBEBEC',
  red: '#FF0000',
}

export default Colors
