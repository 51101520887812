export type AbuseReason = {
  code: number
  description: string
}

export enum ActionStatus {
  WARNED = 'WARNED',
  SUSPENDED = 'SUSPENDED',
  LIFTED = 'LIFTED',
  NO_STATUS = '',
}

export type UserMisconductRequest = {
  userId: number
  status: ActionStatus
  reasonCode: number | null
  referenceNumber: string
}
