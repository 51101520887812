import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface TransferIsPendingType {
  value: boolean
}
const initialState: TransferIsPendingType = {
  value: false,
}
export const transferIsPendingSlice = createSlice({
  name: 'transferIsPending',
  initialState,
  reducers: {
    setTransferIsPending: (state, action: PayloadAction<boolean>) => ({
      ...state,
      value: action.payload,
    }),
  },
})

export const { setTransferIsPending } = transferIsPendingSlice.actions

export default transferIsPendingSlice.reducer
