import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'

import { Car } from '../../types/car.types'
import { Location } from '../../types/Location'
import { TransportRouteModification } from '../../types/transport.types'

export interface CreateTransportOffer {
  id: number | null
  status: string
  car: Car
  availableDate: string
  pickupLocation: Location
  transportRoutes: TransportRouteModification[]
  publicInformation: string | undefined
  publicDescription: string | undefined
  internalNotes: string | undefined
  createdBy: {
    id: string
  }
  quantity: number
  countryCode: 'no' | 'se'
  createdDate: string
  tracDialog: {
    message: string
    open: boolean
  }
}

const initialState: CreateTransportOffer = {
  id: null,
  status: 'AVAILABLE',
  car: {
    licenseNumber: '',
    model: '',
    group: '',
    location: { name: '', tracCode: '', country: '' },
    ready: false,
  },
  availableDate: '',
  pickupLocation: { name: null, tracCode: null, country: null },
  transportRoutes: [
    {
      id: null,
      returnLocation: { name: null, tracCode: null, country: null },
      priorityOrder: 1,
      latestReturn: '',
      availableAt: '',
    },
  ],
  publicInformation: '',
  publicDescription: '',
  internalNotes: '',
  createdBy: {
    id: '1',
  },
  quantity: 1,
  countryCode: 'se',
  createdDate: '',
  tracDialog: {
    message: '',
    open: false,
  },
}

const createTransportOfferSlice: Slice<CreateTransportOffer> = createSlice({
  name: 'createTransportOffer',
  initialState,
  reducers: {
    setCreateTransportCar: (state, action: PayloadAction<Car>) => ({
      ...state,
      car: action.payload,
    }),
    setCreateTransportCarProperty: (state, action: PayloadAction<{ key: keyof Car; value: Car[keyof Car] }>) => {
      const { key, value } = action.payload
      return {
        ...state,
        car: {
          ...state.car,
          [key]: value,
        },
      }
    },
    setCreateTransportAvailableDate: (state, action: PayloadAction<string>) => {
      state.availableDate = action.payload
    },
    setCreateTransportPickupLocation: (state, action: PayloadAction<Location>) => ({
      ...state,
      pickupLocation: action.payload,
    }),
    setCreateTransportReturnLocations: (
      state,
      action: PayloadAction<{
        index: number
        property: keyof TransportRouteModification
        value: Location
      }>,
    ) => {
      const { index, property, value } = action.payload
      if (state.transportRoutes.length > 0 && index < state.transportRoutes.length) {
        const updatedTransportRoutes = [...state.transportRoutes]
        const updatedTransportRoute = {
          ...updatedTransportRoutes[index],
          [property]: value,
        }
        updatedTransportRoutes[index] = updatedTransportRoute

        return {
          ...state,
          transportRoutes: updatedTransportRoutes,
        }
      }
      return state
    },
    setCreateTransportReturnDates: (
      state,
      action: PayloadAction<{
        index: number
        property: keyof TransportRouteModification
        value: string
      }>,
    ) => {
      const { index, property, value } = action.payload
      if (state.transportRoutes.length > 0 && index < state.transportRoutes.length) {
        const updatedTransportRoutes = [...state.transportRoutes]
        const updatedTransportRoute = {
          ...updatedTransportRoutes[index],
          [property]: value,
        }
        updatedTransportRoutes[index] = updatedTransportRoute
        return {
          ...state,
          transportRoutes: updatedTransportRoutes,
        }
      }
      return state
    },
    setCreateTransportPublicInformation: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      publicInformation: action.payload,
    }),
    setCreateTransportPublicDescription: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      publicDescription: action.payload,
    }),
    setCreateTransportInternalNotes: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      internalNotes: action.payload,
    }),
    setCreateTransportQuantity: (state, action: PayloadAction<number>) => ({
      ...state,
      quantity: action.payload,
    }),
    addCreateTransportRoute: (state, action: PayloadAction<number>) => {
      const routeData = {
        id: null,
        returnLocation: { name: null, tracCode: null, country: null },
        priorityOrder: action.payload + 1,
        latestReturn: new Date(new Date().getTime() + 60 * 60 * 1000 * 24).toISOString().slice(0, -8),
      }
      state.transportRoutes.push(routeData)
    },
    removeCreateTransportRoute: (state, action: PayloadAction<number>) => {
      if (state.transportRoutes.length > 1) {
        state.transportRoutes.splice(action.payload, 1)
      }
    },
    setTracDialog: (state, action: PayloadAction<{ message: string; open: boolean }>) => ({
      ...state,
      tracDialog: action.payload,
    }),
    resetCreateTransport: () => initialState,
  },
})

export const {
  setCreateTransportCar,
  setCreateTransportCarProperty,
  setCreateTransportAvailableDate,
  setCreateTransportPickupLocation,
  setCreateTransportReturnDates,
  setCreateTransportReturnLocations,
  setCreateTransportPublicInformation,
  setCreateTransportPublicDescription,
  setCreateTransportInternalNotes,
  setCreateTransportQuantity,
  resetCreateTransport,
  addCreateTransportRoute,
  removeCreateTransportRoute,
  setTracDialog,
} = createTransportOfferSlice.actions

export default createTransportOfferSlice.reducer
