import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Dispatch, MouseEvent, SetStateAction } from 'react'

type Props = {
  open: boolean
  description: string
  toggleDialog: Dispatch<SetStateAction<boolean>>
  onConfirm: (event: MouseEvent<HTMLButtonElement>) => void
}

export default function AlertDialog({ open, description, toggleDialog, onConfirm }: Props) {
  const handleConfirm = (event: MouseEvent<HTMLButtonElement>) => {
    onConfirm(event)
    toggleDialog(false)
  }

  const handleCancel = () => {
    toggleDialog(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} autoFocus>
            Yes
          </Button>
          <Button onClick={handleCancel}>No</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
