import { Container } from '@mui/material'

function UnderConstructionPage() {
  return (
    <Container>
      <p>Page is under construction.</p>
    </Container>
  )
}

export default UnderConstructionPage
