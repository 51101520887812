import axios, { AxiosError, AxiosResponse } from 'axios'

import HandleError from './HandleError'
import { TableDataRequest } from '../types/table.types'
import { AdminCreationRequest, User, UsersResponse } from '../types/user.types'

const usersEndpoint = process.env.REACT_APP_ENDPOINT_USERS

export const getAdmins = async (data: TableDataRequest) => {
  const response = await axios.get<UsersResponse>(`${usersEndpoint}search-admins`, { params: data })
  return response
}

export const getUsers = async (data: TableDataRequest) => {
  const response = await axios.get<UsersResponse>(`${usersEndpoint}search`, { params: data })
  return response
}

export const getUserById = async (id: number | undefined): Promise<User | AxiosError | undefined> => {
  try {
    const response = await axios.get(`${usersEndpoint}${id}`, {})
    return response.data
  } catch (error) {
    HandleError(error)
  }
  return undefined
}

export const updateUser = async (data: User) => {
  try {
    const response = await axios.put(`${usersEndpoint}${data.id}`, data)
    return response.data
  } catch (error) {
    HandleError(error)
    throw error
  }
}

export const upsertAdminRole = async (data: AdminCreationRequest): Promise<AxiosResponse | AxiosError | undefined> => {
  try {
    const response = await axios.post(`${usersEndpoint}admins`, data)
    return response.data
  } catch (error) {
    HandleError(error)
  }
  return undefined
}
