import { DateTime, Settings } from 'luxon'

import { detectLocale } from '../features/localization/detectLocale'

const locale = detectLocale()

if (locale === 'NO') {
  Settings.defaultZone = 'Europe/Oslo'
}
if (locale === 'SE') {
  Settings.defaultZone = 'Europe/Stockholm'
}

export { DateTime, Settings }
