import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UiState {
  isSuperAdmin: boolean
  drawerOpen: boolean
  isAdding: boolean
  formIsDirty: boolean
}

const initialState: UiState = {
  isSuperAdmin: false,
  drawerOpen: false,
  isAdding: false,
  formIsDirty: false,
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsSuperAdmin: (state, action: PayloadAction<boolean>) => {
      state.isSuperAdmin = action.payload
    },
    setDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.drawerOpen = action.payload
    },
    setIsAdding: (state, action: PayloadAction<boolean>) => {
      state.isAdding = action.payload
    },
    setFormIsDirty: (state, action: PayloadAction<boolean>) => {
      state.formIsDirty = action.payload
    },
    resetSideDrawer: (state) => {
      state.drawerOpen = false
      state.isAdding = false
    },
  },
})

export const { setIsSuperAdmin, setDrawerOpen, setIsAdding, resetSideDrawer, setFormIsDirty } = uiSlice.actions

export default uiSlice.reducer
