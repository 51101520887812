import { Middleware } from '@reduxjs/toolkit'

import { Locale, detectLocale } from './detectLocale'

const localeMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  if (action.type === '@@INIT') {
    const locale: Locale = detectLocale()
    storeAPI.dispatch({ type: 'locale/setLocale', payload: locale })
  }

  return next(action)
}
export default localeMiddleware
