import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, styled, useTheme } from '@mui/material'
import { nanoid } from 'nanoid'
import React, { Fragment, memo, ReactElement, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export type NavigationItem = {
  id: number
  icon: ReactElement
  name: string
  path: string
  subItems: NavigationItem[]
}

const SideNavList = styled(List)(() => ({
  padding: 0,
}))

const SideNavListItem = styled(ListItemButton)(({ theme }) => ({
  borderBottomColor: theme.palette.common.primaryBorderColor,
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  '&.Mui-selected': {
    background: theme.palette.common.buttonBackgroundColor,
    ':hover': {
      background: theme.palette.common.buttonBackgroundColor,
    },
  },
}))

type NavigationListProps = {
  sideMenuOpen: boolean
  navigationItems: NavigationItem[]
}

const NavigationList = memo((props: NavigationListProps) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const { sideMenuOpen, navigationItems } = props

  const findIndexValueFromLocationPath = () => {
    let res = 0
    const defaultExpandedItems: number[] = []
    res = navigationItems.find((item) => item.path.toLowerCase() === location.pathname.toLowerCase())?.id || 0

    if (res === 0) {
      navigationItems.forEach((item) => {
        item.subItems.forEach((subItem) => {
          if (subItem.path.toLowerCase() === location.pathname.toLowerCase()) {
            res = subItem.id
            defaultExpandedItems.push(item.id)
          }
        })
      })
    }
    return {
      navIndexValueFromLocationPath: res,
      defaultExpandedItems,
    }
  }
  const { navIndexValueFromLocationPath, defaultExpandedItems } = findIndexValueFromLocationPath()
  const [navIndexesExpanded, setNavIndexesExpanded] = useState<number[]>(defaultExpandedItems)
  const [selectedNavId, setSelectedNavId] = useState<number>(navIndexValueFromLocationPath)

  if (selectedNavId !== navIndexValueFromLocationPath) {
    setSelectedNavId(navIndexValueFromLocationPath)
  }

  const handleNavigationButtonClick = (navItem: NavigationItem) => {
    setSelectedNavId(navItem.id)
    navigate(navItem.path)
  }

  const getNavigationItems = (navItems: NavigationItem[]): JSX.Element[] =>
    navItems.map((navItem) => {
      const itemIsSelected = selectedNavId === navItem.id
      const { subItems } = navItem
      const itemShouldBeExpanded = navIndexesExpanded.includes(navItem.id)

      return (
        <Fragment key={nanoid()}>
          <SideNavListItem
            selected={itemIsSelected}
            key={nanoid()}
            sx={{
              minHeight: 52,
              justifyContent: sideMenuOpen ? 'initial' : 'center',
              px: 2.5,
            }}
            onClick={() => {
              handleNavigationButtonClick(navItem)
              if (itemShouldBeExpanded) {
                const tmpArr = navIndexesExpanded.slice()
                tmpArr.splice(tmpArr.indexOf(navItem.id), 1)
                setNavIndexesExpanded(tmpArr)
              } else {
                const tmpArr = navIndexesExpanded.slice()
                tmpArr.push(navItem.id)
                setNavIndexesExpanded(tmpArr)
              }
            }}
          >
            <>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: sideMenuOpen ? 3 : 'auto',
                  justifyContent: 'center',
                  color: itemIsSelected ? theme.palette.common.buttonFontColor : theme.palette.common.darkGray,
                }}
              >
                {navItem.icon}
              </ListItemIcon>
              <ListItemText
                primary={navItem.name}
                sx={{
                  opacity: sideMenuOpen ? 1 : 0,
                  textDecoration: 'none',
                  color: itemIsSelected ? theme.palette.common.buttonFontColor : theme.palette.common.darkGray,
                }}
              />

              {subItems.length > 0 &&
                (itemShouldBeExpanded ? (
                  <ExpandLess
                    sx={{
                      color: itemIsSelected ? theme.palette.common.buttonFontColor : theme.palette.common.darkGray,
                    }}
                  />
                ) : (
                  <ExpandMore
                    sx={{
                      color: itemIsSelected ? theme.palette.common.buttonFontColor : theme.palette.common.darkGray,
                    }}
                  />
                ))}
            </>
          </SideNavListItem>
          <Collapse in={itemShouldBeExpanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {subItems.map((subNavItem) => {
                const subItemIsSelected = selectedNavId === subNavItem.id

                return (
                  <SideNavListItem
                    selected={subItemIsSelected}
                    key={nanoid()}
                    sx={{
                      minHeight: 52,
                      justifyContent: sideMenuOpen ? 'initial' : 'center',
                      px: 2.5,
                      pl: 4,
                    }}
                    onClick={() => {
                      handleNavigationButtonClick(subNavItem)
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: sideMenuOpen ? 3 : 'auto',
                        justifyContent: 'center',
                        color: subItemIsSelected ? theme.palette.common.buttonFontColor : theme.palette.common.darkGray,
                      }}
                    >
                      {subNavItem.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={subNavItem.name}
                      sx={{
                        opacity: sideMenuOpen ? 1 : 0,
                        textDecoration: 'none',
                        color: subItemIsSelected ? theme.palette.common.buttonFontColor : theme.palette.common.darkGray,
                      }}
                    />
                  </SideNavListItem>
                )
              })}
            </List>
          </Collapse>
        </Fragment>
      )
    })

  return <SideNavList>{getNavigationItems(navigationItems)}</SideNavList>
})

NavigationList.displayName = 'NavigationList'

export default NavigationList
