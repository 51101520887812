import PageLayout from '../features/layout/PageLayout'
import ReservationsTable from '../features/reservations/ReservationsTable'
import TableHeader from '../features/table/TableHeader'

export default function Reservations() {
  return (
    <PageLayout>
      <TableHeader title="Reservations" />
      <ReservationsTable />
    </PageLayout>
  )
}
