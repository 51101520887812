import { Checkbox, Menu, FormControlLabel, SvgIcon } from '@mui/material'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'

import {
  ColumnChoserDivider,
  ColumnChoserMenuButtonLabel,
  ColumnChoserMenuItemButton,
  ColumnChoserMenuItemButtonLabel,
  ColumnChoserMenuResetButtonLabel,
  ColumnChoserMenuWithCheckBoxes,
  ColumnChoserPaper,
} from './ColumnChoser.styles'

type ColumnNames = string[]

interface ColumnChoserProps {
  columnNames: ColumnNames
  uncheckedColumns: ColumnNames
  resetButtonLabel: string
  buttonLabel: string
  buttonIcon?: ReactNode
  getCheckedList?: Function
  getUnCheckedList?: Function
}

export default function ColumnChoser({
  columnNames,
  uncheckedColumns,
  resetButtonLabel,
  buttonLabel,
  buttonIcon = (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        {/* <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->  */}
        <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm64 64V416H224V160H64zm384 0H288V416H448V160z" />
      </svg>
    </SvgIcon>
  ),
  getCheckedList,
  getUnCheckedList,
}: Readonly<ColumnChoserProps>) {
  const showColumns = useCallback(() => {
    const columns: { [key: string]: boolean } = {}
    columnNames
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .forEach((key) => {
        columns[key] = !uncheckedColumns.includes(key)
      })

    return columns
  }, [columnNames, uncheckedColumns])

  const resetcheckedColumns = useCallback(() => {
    const columns: { [key: string]: boolean } = {}
    columnNames
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .forEach((key) => {
        columns[key] = true
      })
    return columns
  }, [columnNames])

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)
  const [checkboxValues, setCheckboxValues] = useState(showColumns)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElement(null)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxValues({ ...checkboxValues, [event.target.name]: event.target.checked })
  }

  const reset = useCallback(() => {
    setCheckboxValues(resetcheckedColumns)
  }, [resetcheckedColumns])

  useEffect(() => {
    if (getCheckedList) {
      const chosedColumnsToShow = Object.keys(checkboxValues).filter((key) => checkboxValues[key] === true)
      getCheckedList(chosedColumnsToShow.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())))
    }
  }, [checkboxValues, getCheckedList])

  useEffect(() => {
    if (getUnCheckedList) {
      const chosedColumnsToHide = Object.keys(checkboxValues).filter((key) => checkboxValues[key] === false)
      getUnCheckedList(chosedColumnsToHide.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())))
    }
  }, [checkboxValues, getUnCheckedList])

  return (
    <ColumnChoserPaper elevation={0}>
      <ColumnChoserMenuWithCheckBoxes
        size="small"
        startIcon={buttonIcon}
        aria-controls="dropdown-checkboxes"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ColumnChoserMenuButtonLabel>{buttonLabel}</ColumnChoserMenuButtonLabel>
      </ColumnChoserMenuWithCheckBoxes>
      <Menu
        id="dropdown-checkboxes"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleClose}
        style={{ height: 'auto' }}
      >
        <ColumnChoserMenuItemButton onClick={reset}>
          <ColumnChoserMenuResetButtonLabel>{resetButtonLabel}</ColumnChoserMenuResetButtonLabel>
        </ColumnChoserMenuItemButton>
        <ColumnChoserDivider />
        {columnNames.map((columnName) => (
          <span key={columnName}>
            <ColumnChoserMenuItemButton>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkboxValues[columnName]}
                    onChange={handleCheckboxChange}
                    name={columnName}
                    style={{
                      color: checkboxValues[columnName] ? '#1976D2' : '#666666',
                    }}
                  />
                }
                label={<ColumnChoserMenuItemButtonLabel>{columnName}</ColumnChoserMenuItemButtonLabel>}
              />
            </ColumnChoserMenuItemButton>
            <ColumnChoserDivider />
          </span>
        ))}
      </Menu>
    </ColumnChoserPaper>
  )
}
