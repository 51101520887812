import { useAccount, useMsal } from '@azure/msal-react'
import { Button, Card, CardContent, Container, Stack, Typography } from '@mui/material'

import { login } from '../../api/auth'
import LogoSrc from '../../assets/logo.svg'
import LoadingBar from '../loading-bar/LoadingBar'

export default function Login() {
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0])

  return account ? (
    <LoadingBar />
  ) : (
    <Container maxWidth="sm">
      <Card variant="outlined" sx={{ backgroundColor: '#f1f1f1' }}>
        <CardContent>
          <Stack alignItems="center" spacing={0.5}>
            <img src={LogoSrc} width={150} alt="Hertz" />
            <Typography variant="h5">Freerider Admin Portal</Typography>
            <Typography variant="body1">Welcome to the Freerider Admin Portal. Please login.</Typography>
            <Button onClick={() => login(instance)}>Log in</Button>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  )
}
