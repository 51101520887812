import { TableDataRequest, TableSettings } from '../types/table.types'

const mapTableSettings = (settings: TableSettings): TableDataRequest => ({
  page: settings.page,
  pageSize: settings.pageSize,
  sortBy: settings.sortBy || null,
  sortDirection: settings.sortDirection === 'asc' ? 'ASC' : 'DESC',
  freeText: settings.freeText,
  statusFilter: settings.statusFilter || null,
})

export default mapTableSettings
