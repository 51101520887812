import { FormControl, styled, Theme } from '@mui/material'

const PhoneInputWrapper = styled(FormControl)(({ theme, error }: { theme: Theme; error: boolean }) => ({
  width: '100%',
  '&& .form-control': {
    height: '40px',
    width: '100%',
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(1.0625, 1.75),
    paddingLeft: theme.spacing(6),
  },

  '&& .react-tel-input .flag-dropdown': {
    fontSize: '16px',
  },
  '&& .country-list': {
    margin: 0,
    borderRadius: '0 0 7px 7px',
    boxShadow: '0px 2px 1px rgb(0, 0, 0, 0.25)',

    'li:not(.divider)': {
      padding: theme.spacing(2, 1.125, 2, 5.75),
    },

    "li[aria-selected='true'], li:hover": {
      backgroundColor: theme.palette.secondary,
    },

    '.flag': {
      top: '50%',
      transform: 'translate(0, -50%)',
      marginTop: theme.spacing(-0.25),
      left: theme.spacing(1.375),
      padding: theme.spacing(1.25),
    },
  },

  '&& .react-tel-input .form-control:focus': {
    borderColor: error ? '#CD2026' : theme.palette.secondary,
    boxShadow: `inset 0 0 0 1px ${error ? '#CD2026' : theme.palette.secondary}`,
  },

  '&& .react-tel-input:focus-within .special-label': {
    color: error ? '#CD2026' : theme.palette.secondary,
  },
}))

export default PhoneInputWrapper
