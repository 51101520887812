import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'

import { Car } from '../../types/car.types'
import { Location } from '../../types/Location'
import { ReservationResponse } from '../../types/Reservation'
import { TransportRouteModification } from '../../types/transport.types'
import { AdminAccessRole, UserStatus, UserUpdatedBy } from '../../types/user.types'

export interface UpdateTransportOffer {
  id: number
  status: 'AVAILABLE' | 'BOOKED' | 'EXPIRED' | 'LOCKED' | string
  car: Car
  availableDate: string
  pickupLocation: Location
  transportRoutes: TransportRouteModification[]
  publicInformation: string | undefined
  publicDescription: string | undefined
  internalNotes: string | undefined
  updatedBy: UserUpdatedBy
  countryCode: 'no' | 'se'
  createdDate: string
  createdBy: {
    id: number
    iamId: string
    firstName: string
    lastName: string
    address: string
    postalCode: string
    city: string
    emailAddress: string
    phoneNumber: string
    countryCode: 'no' | 'se'
    userStatus: UserStatus
    adminRole?: AdminAccessRole
    numberOfFreerides: number
    cancelledFreerides: number
  }
  updatedDate: string
  reservation?: ReservationResponse | null
}

const initialState: UpdateTransportOffer = {
  id: 0,
  status: '',
  car: {
    licenseNumber: '',
    model: '',
    group: '',
    location: { name: '', tracCode: '', country: '' },
    ready: false,
  },
  availableDate: '',
  pickupLocation: { name: null, tracCode: null, country: null },
  transportRoutes: [
    { id: null, returnLocation: { name: null, tracCode: null, country: null }, latestReturn: '', availableAt: '' },
  ],
  publicInformation: '',
  publicDescription: '',
  internalNotes: '',
  updatedBy: {
    id: 0,
    iamId: '',
    firstName: '',
    lastName: '',
  },
  createdDate: '',
  countryCode: 'se',
  createdBy: {
    id: 0,
    iamId: '',
    firstName: '',
    lastName: '',
    address: '',
    postalCode: '',
    city: '',
    emailAddress: '',
    phoneNumber: '',
    countryCode: 'se',
    userStatus: UserStatus.PENDING,
    numberOfFreerides: 0,
    cancelledFreerides: 0,
  },
  updatedDate: '',
}

const updateTransportOfferSlice: Slice<UpdateTransportOffer> = createSlice({
  name: 'updateTransportOffer',
  initialState,
  reducers: {
    setUpdateTransport: (state, action: PayloadAction<UpdateTransportOffer>) => ({
      ...state,
      ...action.payload,
    }),
    setUpdateTransportCarProperty: (state, action: PayloadAction<{ key: keyof Car; value: Car[keyof Car] }>) => {
      const { key, value } = action.payload
      return {
        ...state,
        car: {
          ...state.car,
          [key]: value,
        },
      }
    },
    setUpdateTransportAvailableDate: (state, action: PayloadAction<string>) => ({
      ...state,
      availableDate: action.payload,
    }),
    setUpdateTransportPickupLocation: (state, action: PayloadAction<Location>) => ({
      ...state,
      pickupLocation: action.payload,
    }),
    setUpdateTransportReturnLocations: (
      state,
      action: PayloadAction<{
        index: number
        property: keyof TransportRouteModification
        value: Location
      }>,
    ) => {
      const { index, property, value } = action.payload

      if (state.transportRoutes.length > 0 && index < state.transportRoutes.length) {
        const updatedTransportRoutes = [...state.transportRoutes]
        const updatedTransportRoute = {
          ...updatedTransportRoutes[index],
          [property]: value,
        }
        updatedTransportRoutes[index] = updatedTransportRoute

        return {
          ...state,
          transportRoutes: updatedTransportRoutes,
        }
      }
      return state
    },
    setUpdateTransportReturnDates: (
      state,
      action: PayloadAction<{
        index: number
        property: keyof TransportRouteModification
        value: string
      }>,
    ) => {
      const { index, property, value } = action.payload

      if (state.transportRoutes.length > 0 && index < state.transportRoutes.length) {
        const updatedTransportRoutes = [...state.transportRoutes]
        const updatedTransportRoute = {
          ...updatedTransportRoutes[index],
          [property]: value,
        }
        updatedTransportRoutes[index] = updatedTransportRoute

        return {
          ...state,
          transportRoutes: updatedTransportRoutes,
        }
      }
      return state
    },
    setUpdateTransportPublicInformation: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      publicInformation: action.payload,
    }),
    setUpdateTransportPublicDescription: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      publicDescription: action.payload,
    }),
    setUpdateTransportInternalNotes: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      internalNotes: action.payload,
    }),
    addUpdateTransportRoute: (state, action: PayloadAction<number>) => {
      const routeData = {
        id: null,
        returnLocation: { name: null, tracCode: null, country: null },
        priorityOrder: action.payload + 1,
        latestReturn: new Date(new Date().getTime() + 60 * 60 * 1000 * 24).toISOString().slice(0, -8),
      }
      state.transportRoutes.push(routeData)
    },
    removeUpdateTransportRoute: (state, action: PayloadAction<number>) => {
      if (state.transportRoutes.length > 1) {
        state.transportRoutes.splice(action.payload, 1)
      }
    },
  },
})

export const {
  setUpdateTransport,
  setUpdateTransportCarProperty,
  setUpdateTransportAvailableDate,
  setUpdateTransportPickupLocation,
  setUpdateTransportReturnLocations,
  setUpdateTransportReturnDates,
  setUpdateTransportPublicInformation,
  setUpdateTransportPublicDescription,
  setUpdateTransportInternalNotes,
  addUpdateTransportRoute,
  removeUpdateTransportRoute,
} = updateTransportOfferSlice.actions

export default updateTransportOfferSlice.reducer
