import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import React from 'react'
import { useSelector } from 'react-redux'

import { closeSnackbar } from './snackbarSlice'
import { RootState, useAppDispatch } from '../../../store/store'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />
))

Alert.displayName = 'Alert'

function CustomSnackbar() {
  const appDispatch = useAppDispatch()
  const { message, type, open } = useSelector((state: RootState) => state.snackbar)

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    appDispatch(closeSnackbar())
  }

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  )
}

CustomSnackbar.displayName = 'CustomSnackbar'

export default CustomSnackbar
