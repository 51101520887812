import { AccountInfo } from '@azure/msal-browser'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useMsal } from '@azure/msal-react'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

import { logout, setAccessToken } from '../../api/auth'
import Header from '../../components/core/Header'
import NavigationBar, { DrawerHeader } from '../../components/core/NavigationBar'
import navigationItems from '../../models/NavigationItems'
import { getLoggedInUser } from '../../store/slices/authSlice'
import { RootState, useAppDispatch } from '../../store/store'
import { User } from '../../types/user.types'
import LoadingBar from '../loading-bar/LoadingBar'
import Login from '../login/Login'
import { setIsSuperAdmin } from '../ui'
import CustomSnackbar from '../ui/snackbar/CustomSnackbar'

export default function AuthLayout() {
  const { loadingAuth, user } = useSelector((state: RootState) => state.auth)

  const [logoutInProgress, setLogoutInProgress] = useState(false)
  const [userInfoHandled, setUserInfoHandled] = useState(false)
  const [userIsAdmin, setUserIsAdmin] = useState(false)
  const [sideMenuOpen, setSideMenuOpen] = useState(true)

  const appDispatch = useAppDispatch()

  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0])

  const handleLogout = useCallback(() => {
    setLogoutInProgress(true)
    logout(instance)
  }, [instance])

  const handleLoginInfo = useCallback(
    async (loggedInAccount: AccountInfo) => {
      if (loadingAuth === 'idle') {
        const tokenSet = await setAccessToken(instance, loggedInAccount)
        if (tokenSet) {
          appDispatch(getLoggedInUser())
        } else {
          handleLogout()
        }
      }
      if (loadingAuth === 'succeeded') {
        await setAccessToken(instance, loggedInAccount)
      }
      setUserInfoHandled(true)
    },
    [loadingAuth, instance, appDispatch, handleLogout],
  )

  const handleAccessRole = useCallback(
    (currentUser: User) => {
      if (currentUser.adminRole === null) {
        handleLogout()
        return
      }
      if (currentUser.adminRole === 'SUPER') {
        appDispatch(setIsSuperAdmin(true))
      }
      setUserIsAdmin(true)
    },
    [appDispatch, handleLogout],
  )

  useEffect(() => {
    if (account) {
      handleLoginInfo(account)
    }
  }, [account, handleLoginInfo])

  useEffect(() => {
    if (user && userInfoHandled) {
      handleAccessRole(user)
    }
  }, [user, userInfoHandled, handleAccessRole])

  return (
    <>
      <AuthenticatedTemplate>
        {userIsAdmin ? (
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Header
              sideMenuOpen={sideMenuOpen}
              toggleSideMenuOpen={() => setSideMenuOpen(!sideMenuOpen)}
              handleLogout={() => handleLogout()}
            />
            <NavigationBar sideMenuOpen={sideMenuOpen} navItems={navigationItems} />
            <Box component="main" sx={{ flexGrow: 1 }}>
              <DrawerHeader />
              <Outlet />
              <CustomSnackbar />
            </Box>
          </Box>
        ) : (
          <LoadingBar />
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>{!logoutInProgress ? <Login /> : null}</UnauthenticatedTemplate>
    </>
  )
}
