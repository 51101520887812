import { IconButton, Paper } from '@mui/material'

import { useNavigate } from 'react-router-dom'

import { DivStyledAsButton, Card, IconWrapper } from './DashboardCard.Styles'

interface DashboardCardProps {
  navigateTo: string
  icon: React.ReactNode
  buttonText: string
}

export default function DashboardCard({ navigateTo, icon, buttonText }: Readonly<DashboardCardProps>) {
  const navigate = useNavigate()
  return (
    <Card
      component={Paper}
      onClick={() => {
        navigate(navigateTo)
      }}
    >
      <IconWrapper>
        <IconButton>{icon}</IconButton>
      </IconWrapper>
      <DivStyledAsButton>{buttonText}</DivStyledAsButton>
    </Card>
  )
}
