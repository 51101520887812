import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getReservations } from '../../api/reservations'
import { ReservationsResponse, Reservation } from '../../types/Reservation'
import { ReservationStatus } from '../../types/ReservationStatus'
import { TableLoadingState, TableSettings } from '../../types/table.types'
import mapTableSettings from '../../utils/mapTableSettings'

export interface ReservationsState {
  reservations: Reservation[] | null
  loadingReservations: TableLoadingState
  tableSettings: TableSettings
}

export const initialState: ReservationsState = {
  reservations: null,
  loadingReservations: 'idle',
  tableSettings: {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    totalSize: undefined,
    columnOrder: [],
    hiddenColumns: [],
    statusFilter: '',
    freeText: '',
    sortBy: '',
    sortDirection: 'asc',
  },
}

function mapPassedStatus(reservationRes: ReservationsResponse) {
  if (!reservationRes || !reservationRes.items || !reservationRes.items.length) {
    return
  }

  const now = new Date()

  reservationRes.items.forEach((reservation) => {
    if (reservation.status !== 'CREATED' || now < new Date(reservation.returnDate)) {
      return
    }
    reservation.status = ReservationStatus.PASSED
  })
}

export const fetchReservations = createAsyncThunk(
  'reservations/fetchReservations',
  async (settings: TableSettings, thunkAPI) => {
    try {
      const response = await getReservations(mapTableSettings(settings))
      mapPassedStatus(response.data)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const reservationsSlice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {
    setLoadingReservations: (state, action: PayloadAction<TableLoadingState>) => {
      state.loadingReservations = action.payload
    },
    setReservationsColumnOrder: (state, action: PayloadAction<string[]>) => {
      state.tableSettings.columnOrder = action.payload
    },
    setReservationsHiddenColumns: (state, action: PayloadAction<string[]>) => {
      state.tableSettings.hiddenColumns = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReservations.pending, (state) => {
        state.loadingReservations = 'pending'
      })
      .addCase(fetchReservations.fulfilled, (state, action) => {
        const { items, query, ...rest } = action.payload

        state.reservations = items
        state.tableSettings = {
          ...state.tableSettings,
          // FIX - CJ-2901 - query possibly missing in response, make sure this works
          statusFilter: query.statusFilter ?? '',
          freeText: query.freeText,
          sortBy: query.sortBy,
          sortDirection: query.sortDirection === 'ASC' ? 'asc' : 'desc',
          ...rest,
        }
        state.loadingReservations = 'succeeded'
      })
      .addCase(fetchReservations.rejected, (state) => {
        state.loadingReservations = 'failed'
      })
  },
})

export const { setLoadingReservations, setReservationsColumnOrder, setReservationsHiddenColumns } =
  reservationsSlice.actions

export default reservationsSlice.reducer
