import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import {
  getAdminsStateFromLocalStorage,
  getReservationsStateFromLocalStorage,
  getTableSettingsStateFromLocalStorage,
  getTransportOffersStateFromLocalStorage,
  getUsersStateFromLocalStorage,
  setLocalStorageMiddleware,
} from './middleware/localStorageMiddleware'
import adminsReducer from './slices/adminsSlice'
import authReducer from './slices/authSlice'
import createTransportOfferReducer from './slices/createTransportOfferSlice'
import reservationsReducer from './slices/reservationsSlice'
import transferIsPendingReducer from './slices/transferIsPendingSlice'
import transportOffersReducer from './slices/transportOfferSlice'
import updateTransportOfferReducer from './slices/updateTransportOfferSlice'
import usersReducer from './slices/usersSlice'

import abuseAlertDialogReducer from '../features/abuse-dialog/abuseDialogSlice'
import localeMiddleware from '../features/localization/detectLocaleMiddleware'
import localeSliceReducer from '../features/localization/localeSlice'
import locationsReducer from '../features/locations/locationSlice'
import { tableSettingsReducer } from '../features/table/tableSettingsSlice'
import snackbarReducer from '../features/ui/snackbar/snackbarSlice'
import uiSliceReducer from '../features/ui/uiSlice'

const preloadedState = {
  admins: getAdminsStateFromLocalStorage(),
  tableSettings: getTableSettingsStateFromLocalStorage(),
  reservations: getReservationsStateFromLocalStorage(),
  transportOffers: getTransportOffersStateFromLocalStorage(),
  users: getUsersStateFromLocalStorage(),
}

const store = configureStore({
  reducer: {
    auth: authReducer,
    locations: locationsReducer,
    reservations: reservationsReducer,
    transportOffers: transportOffersReducer,
    transferIsPending: transferIsPendingReducer,
    abuseDialog: abuseAlertDialogReducer,
    createTransportOffer: createTransportOfferReducer,
    updateTransportOffer: updateTransportOfferReducer,
    snackbar: snackbarReducer,
    tableSettings: tableSettingsReducer,
    locale: localeSliceReducer,
    ui: uiSliceReducer,
    admins: adminsReducer,
    users: usersReducer,
  },
  preloadedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(setLocalStorageMiddleware, localeMiddleware),
})
store.dispatch({ type: '@@INIT' })

export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
export type RootState = ReturnType<typeof store.getState>

export default store
