import { Container, Stack } from '@mui/material'
import { PropsWithChildren } from 'react'

export default function PageLayout({ children }: PropsWithChildren) {
  return (
    <Container maxWidth={false} sx={{ paddingTop: 3 }}>
      <Stack spacing={2}>{children}</Stack>
    </Container>
  )
}
