import { Stack } from '@mui/material'

import { DashboardHeader, DashboardText, DashboardCards } from '.'

export default function Dashboard() {
  return (
    <>
      <Stack paddingTop={3} spacing={2}>
        <DashboardHeader />
        <DashboardText />
      </Stack>
      <DashboardCards />
    </>
  )
}
