import { createTheme } from '@mui/material/styles'

import Colors from '../colors/Colors'

const theme = createTheme({
  palette: {
    info: {
      main: Colors.btnSecondaryBgColor,
    },
    background: {
      default: Colors.mainContentBackgroundColor,
    },
    primary: {
      main: Colors.buttonBackgroundColor,
    },
    secondary: {
      main: Colors.btnSecondaryBgColor,
    },
    // NOTE: The palette's common colors need to be in 'interface CommonColors' in src\styles\theme\createPalette.d.ts
    common: {
      navBackgroundColor: Colors.navBackgroundColor,
      buttonBackgroundColor: Colors.buttonBackgroundColor,
      buttonFontColor: Colors.buttonFontColor,
      chipAvailableBackgroundColor: Colors.chipAvailableBackgroundColor,
      chipBookedBackgroundColor: Colors.chipBookedBackgroundColor,
      chipExpiredBackgroundColor: Colors.chipExpiredBackgroundColor,
      chipPrimaryFontColor: Colors.chipPrimaryFontColor,
      chipSecondaryFontColor: Colors.chipSecondaryFontColor,
      primaryBorderColor: Colors.primaryBorderColor,
      mainContentBackgroundColor: Colors.mainContentBackgroundColor,
      darkGray: Colors.darkGray,
      lightGrayish: Colors.lightGrayish,
      red: Colors.red,
      black: Colors.black,
    },
  },
  typography: {
    fontFamily: `'Inter', sans-serif;`,
  },
})

export default theme
