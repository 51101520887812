import { Box, styled, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

import { RootState } from '../../../store/store'

const TitleBox = styled(Box)(() => ({
  color: '#000000',
  fontSize: '24px',
  fontWeight: 400,
}))

export default function DashboardHeader() {
  const { user } = useSelector((state: RootState) => state.auth)

  return (
    <TitleBox display="flex" justifyContent="space-between" alignItems="flex-end">
      <Typography variant="h5">Welcome {user ? `${user.firstName} ${user.lastName}` : ''}</Typography>
    </TitleBox>
  )
}
