import { TransportResponseError } from '../types/transport.types'

const getResponseError = (error: any): TransportResponseError | null => {
  if (
    error !== null &&
    typeof error === 'object' &&
    'errorCode' in error &&
    'errorMessage' in error &&
    'errorEntity' in error
  ) {
    return error
  }
  return null
}

export default getResponseError
