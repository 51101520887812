import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SnackbarState {
  message: string
  type: 'success' | 'error'
  open: boolean
}

const initialState: SnackbarState = {
  message: '',
  type: 'success',
  open: false,
}

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbar: (state, action: PayloadAction<Omit<SnackbarState, 'open'>>) => {
      state.message = action.payload.message
      state.type = action.payload.type
      state.open = true
    },
    closeSnackbar: (state) => {
      state.open = false
    },
  },
})

export const { setSnackbar, closeSnackbar } = snackbarSlice.actions

export default snackbarSlice.reducer
