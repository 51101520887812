import axios from 'axios'

const HandleError = (error: unknown) => {
  let errorCode: string | undefined
  let errorStatus: number | undefined

  if (axios.isAxiosError(error)) {
    if (error.response) {
      console.error('[API Error]. Status:', error.response.status)
      errorStatus = error.response.status
    } else {
      console.error('[Network-Level Error]. No response received')
      if (error.request) console.error('error.request:', error.request)
    }
    errorCode = error.code
  } else {
    console.error('[Unexpected Non-HTTP Error]:', error)
  }

  return {
    success: false,
    errorCode,
    errorStatus,
  }
}

export default HandleError
