import Button from '@mui/material/Button'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const styles: React.CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
}

function NotFound() {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/')
  }

  return (
    <div style={styles}>
      <h1>Page Not Found</h1>
      <p>The page you are looking for cannot be found or does not exist.</p>
      <Button variant="contained" color="primary" onClick={handleClick}>
        Click me to return to home
      </Button>
    </div>
  )
}

export default NotFound
