import { DateTime } from './luxonDefaults'

export const convertUTCToLocal = (utcDateTime: string): string => {
  if (!utcDateTime) {
    return ''
  }

  const date = new Date(`${utcDateTime}Z`)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  return `${year}-${month}-${day}T${hours}:${minutes}`
}

export const formatIfInitialFormDate = (isoDate: string): string => {
  const parsedDate = DateTime.fromFormat(isoDate, 'MM/dd/yyyy HH:mm')

  if (parsedDate.isValid) {
    return parsedDate.toFormat("yyyy-MM-dd'T'HH:mm:ss")
  }
  return isoDate
}

export const getDatePlusOffset = (isoDate: string): DateTime => {
  const date = DateTime.fromISO(isoDate)
  if (date.isInDST) {
    return date.plus({ hour: 2 })
  }
  return date.plus({ hour: 1 })
}
