import { styled, Paper, Divider, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material'
import { useState } from 'react'

import { FilterItem } from '../../../types/table.types'

const FilterButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.MuiButtonBase-root': {
      padding: theme.spacing(0.5, 0.875),
      color: '#45454E',
      '&:hover': {
        backgroundColor: '#D5E3ED',
      },
      '&.Mui-selected': {
        backgroundColor: '#ffffff',
      },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
      },
      '& .MuiTouchRipple-root': {
        color: '#5589ae',
      },
    },
  },
}))

const FilterButtonLabel = styled('span')(() => ({
  fontWeight: 600,
  fontSize: '12px',
}))

type FilterButton = {
  label: string
  value: string
  callback: Function
}

interface Props extends ToggleButtonGroupProps {
  filterItems: FilterItem[]
  defaultFilter: string | null
  onFilterChange: (value: string) => void
}

export default function FilterButtons({ filterItems, defaultFilter, onFilterChange }: Props) {
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter)

  const filterButtons: FilterButton[] = filterItems.map((filterItem) => ({
    label: filterItem.label,
    value: filterItem.value,
    callback: () => {
      onFilterChange(filterItem.callback.toString())
    },
  }))

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'inline-flex',
        flexWrap: 'wrap',
        width: 'auto',
        backgroundColor: '#E8EFF4',
      }}
    >
      <FilterButtonGroup value={selectedFilter} exclusive>
        <ToggleButton
          value=""
          onClick={(event) => {
            event.preventDefault()
            setSelectedFilter('')
            onFilterChange('')
          }}
          sx={{ textTransform: 'capitalize' }}
        >
          <FilterButtonLabel>show all</FilterButtonLabel>
        </ToggleButton>
      </FilterButtonGroup>
      <Divider flexItem orientation="vertical" sx={{ mx: 0, my: 1.3 }} />

      {filterButtons && (
        <FilterButtonGroup value={selectedFilter} exclusive>
          {filterButtons.map((filterButton) => (
            <ToggleButton
              key={filterButton.value}
              value={filterButton.value}
              onClick={(event) => {
                event.preventDefault()
                setSelectedFilter(filterButton.value)
                filterButton.callback(filterButton.value)
              }}
              sx={{ textTransform: 'capitalize' }}
            >
              <FilterButtonLabel>{filterButton.label.toLowerCase()}</FilterButtonLabel>
            </ToggleButton>
          ))}
        </FilterButtonGroup>
      )}
    </Paper>
  )
}
