import { Box, Typography } from '@mui/material'
import { FaPlus } from 'react-icons/fa'

import { useAppDispatch } from '../../store/store'
import FRButton from '../../styles/buttons/Button'
import theme from '../../styles/theme/Theme'
import { setDrawerOpen, setIsAdding } from '../ui'

type Props = {
  title: string
  buttonLabel?: string
  disabledButton?: boolean
}

export default function TableHeader({ title, disabledButton, buttonLabel = '' }: Props) {
  const appDispatch = useAppDispatch()

  return (
    <Box display="flex" justifyContent="space-between">
      <Typography sx={{ fontSize: '24px', color: theme.palette.text.primary }} variant="h5">
        {title}
      </Typography>
      {buttonLabel && (
        <FRButton
          className="space-beneath"
          label={
            <>
              <FaPlus style={{ marginRight: theme.spacing(1.25) }} />
              {buttonLabel}
            </>
          }
          {...(!disabledButton && {
            onClick: () => {
              appDispatch(setDrawerOpen(true))
              appDispatch(setIsAdding(true))
            },
          })}
          size="medium"
          isDisabled={disabledButton}
        />
      )}
    </Box>
  )
}
