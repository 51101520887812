import { Button, Divider, MenuItem, Paper, styled } from '@mui/material'

import theme from '../../../styles/theme/Theme'

export const ColumnChoserMenuWithCheckBoxes = styled(Button)(() => ({
  margin: theme.spacing(0.5),
  border: '0',
  color: '#45454E',
  textTransform: 'capitalize',

  backgroundColor: '#E8EFF4',
  '&:hover': {
    color: '#45454E',
    backgroundColor: '#D5E3ED',
  },
  '&:active': {
    backgroundColor: '#45454E',
    color: '#B0CDE1',
    outline: 'none',
  },
  '.MuiTouchRipple-root': {
    color: '#5589ae',
  },
}))

export const ColumnChoserMenuButtonLabel = styled('span')(() => ({
  fontWeight: '600',
  fontSize: '12px',
  color: '#45454E',
}))

export const ColumnChoserMenuResetButtonLabel = styled('span')(() => ({
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '24px',
  margin: '0 auto',
}))

export const ColumnChoserMenuItemButtonLabel = styled('span')(() => ({
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '24px',
}))

export const ColumnChoserMenuItemButton = styled(MenuItem)(() => ({
  color: '#212121',
  height: '48px',
  marginTop: '0px',
  marginBottom: '0px',
  paddingTop: '0px',
  paddingBottom: '0px',
}))

export const ColumnChoserPaper = styled(Paper)(() => ({
  display: 'inline-flex',
  flexWrap: 'wrap',
  width: 'auto',
  backgroundColor: '#E8EFF4',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
}))

export const ColumnChoserDivider = styled(Divider)(() => ({
  marginTop: '0px',
  marginBottom: '0px',
  paddingTop: '0px',
  paddingBottom: '0px',
}))
