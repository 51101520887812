import { AccountInfo, AuthenticationResult, BrowserAuthError, IPublicClientApplication } from '@azure/msal-browser'
import axios, { AxiosResponse } from 'axios'

import { loginRequest } from '../authConfig'
import { User } from '../types/user.types'
import { isSuccessfulResponse } from '../utils/httpHelpers'

export const login = async (instance: IPublicClientApplication) => {
  try {
    await instance.loginRedirect(loginRequest)
  } catch (error) {
    if (!(error instanceof BrowserAuthError && (error as BrowserAuthError).errorCode === 'user_cancelled')) {
      console.error('Error when trying to log in:', error)
    }
  }
}

export const logout = (instance: IPublicClientApplication) => {
  instance.logoutRedirect({
    postLogoutRedirectUri: '/',
  })
}

export const setAccessToken = async (
  instance: IPublicClientApplication,
  account: AccountInfo,
): Promise<AuthenticationResult | null> => {
  try {
    const response = await instance.acquireTokenSilent({ ...loginRequest, account })

    if (response) {
      axios.defaults.headers.common.Authorization = `Bearer ${response.accessToken}`
      return response
    }
    return null
  } catch {
    return null
  }
}

export const getCurrentUser = async (): Promise<User | null> => {
  try {
    const response: AxiosResponse<User> = await axios.get<User>(process.env.REACT_APP_ENDPOINT_CURRENTUSER)

    if (isSuccessfulResponse(response.status)) {
      return response.data
    }
  } catch (error) {
    return null
  }
  return null
}
