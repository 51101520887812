import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import api from '../../api/transportOffer'
import { TableLoadingState, TableSettings } from '../../types/table.types'
import { TransportOffer, TransportResponseError } from '../../types/transport.types'
import mapTableSettings from '../../utils/mapTableSettings'

export interface TransportOffersState {
  transportOffers: TransportOffer[] | null
  loadingTransportOffers: TableLoadingState
  tableSettings: TableSettings
}

export const initialState: TransportOffersState = {
  transportOffers: null,
  loadingTransportOffers: 'idle',
  tableSettings: {
    page: 1,
    pageCount: 0,
    pageSize: 10,
    totalSize: undefined,
    columnOrder: [],
    hiddenColumns: [],
    statusFilter: '',
    freeText: '',
    sortBy: '',
    sortDirection: 'asc',
  },
}

export const fetchTransportOffers = createAsyncThunk(
  'transportOffers/fetchTransports',
  async (settings: TableSettings, thunkAPI) => {
    try {
      const response = await api.getTransportOffers(mapTableSettings(settings))
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const deleteTransportOffers = createAsyncThunk<
  boolean | TransportResponseError,
  number[],
  { state: { transportOffers: TransportOffersState } }
>('transportOffers/deleteTransports', async (transports: number[], { getState, dispatch }) => {
  try {
    const response = await api.deleteTransportOffers(transports)
    if (response === true) {
      const { transportOffers } = getState()
      await dispatch(fetchTransportOffers(transportOffers.tableSettings))
    } else {
      return response
    }
  } catch (error) {
    return false
  }
  return true
})

export const reloadTransportOffers = createAsyncThunk<void, void, { state: { transportOffers: TransportOffersState } }>(
  'transportOffers/reloadTransports',
  async (_: void, { getState, dispatch }) => {
    const { transportOffers } = getState()
    await dispatch(fetchTransportOffers(transportOffers.tableSettings))
  },
)

export const transportOffersSlice = createSlice({
  name: 'transportOffers',
  initialState,
  reducers: {
    setLoadingTransports: (state, action: PayloadAction<TableLoadingState>) => {
      state.loadingTransportOffers = action.payload
    },
    setTransportsColumnOrder: (state, action: PayloadAction<string[]>) => {
      state.tableSettings.columnOrder = action.payload
    },
    setTransportsHiddenColumns: (state, action: PayloadAction<string[]>) => {
      state.tableSettings.hiddenColumns = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransportOffers.pending, (state) => {
        state.loadingTransportOffers = 'pending'
      })
      .addCase(fetchTransportOffers.fulfilled, (state, action) => {
        const { items, query, ...rest } = action.payload

        state.transportOffers = items
        state.tableSettings = {
          ...state.tableSettings,
          // FIX - CJ-2900 - query missing in response atm
          statusFilter: query.statusFilter ?? '',
          freeText: query.freeText,
          sortBy: query.sortBy,
          sortDirection: query.sortDirection === 'ASC' ? 'asc' : 'desc',
          ...rest,
        }
        state.loadingTransportOffers = 'succeeded'
      })
      .addCase(fetchTransportOffers.rejected, (state) => {
        state.loadingTransportOffers = 'failed'
      })
  },
})

export const { setLoadingTransports, setTransportsColumnOrder, setTransportsHiddenColumns } =
  transportOffersSlice.actions

export default transportOffersSlice.reducer
