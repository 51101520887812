import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import getAllLocations from './api/Locations'
import { Location } from '../../types/station.types'

export interface LocationState {
  locations: Location[]
  loadingLocations: 'idle' | 'pending' | 'succeeded' | 'failed'
}

const initialState: LocationState = { locations: [], loadingLocations: 'idle' }

export const fetchAllLocations = createAsyncThunk('locations/fetchAll', async (countryCode?: string) => {
  try {
    if (countryCode) {
      const response = await getAllLocations(countryCode)
      return response
    }
    const response = await getAllLocations()
    return response
  } catch (error) {
    throw Error('failed to get locations')
  }
})

const locationSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLocations.pending, (state) => {
        state.loadingLocations = 'pending'
      })
      .addCase(fetchAllLocations.fulfilled, (state, action) => {
        state.locations = action.payload
        state.loadingLocations = 'succeeded'
      })
      .addCase(fetchAllLocations.rejected, (state, action) => {
        if (action.payload) {
          console.error(action.payload)
        } else {
          console.error(action.error.message)
        }
        state.loadingLocations = 'failed'
      })
  },
})

export default locationSlice.reducer
