import axios from 'axios'

import { Location } from '../../../types/station.types'
import { sortLocations } from '../../../utils/httpHelpers'

const ENDPOINT_LOCATIONS: string = process.env.REACT_APP_ENDPOINT_LOCATIONS

const getAllLocations = async (countryCode?: string): Promise<Location[]> => {
  let response
  if (countryCode) {
    response = await axios.get(`${ENDPOINT_LOCATIONS}?country=${countryCode}`)
  } else {
    response = await axios.get(ENDPOINT_LOCATIONS)
  }
  return sortLocations(response.data)
}

export default getAllLocations
