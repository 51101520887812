import { COUNTRY, COUNTRYCODE } from '../types/Countrys'
import { ReservationStatus } from '../types/ReservationStatus'
import { FilterItem } from '../types/table.types'
import { TransportStatus } from '../types/transport.types'
import { AdminAccessRole, UserStatus } from '../types/user.types'

export const transportFilterItems: FilterItem[] = [
  {
    label: TransportStatus.AVAILABLE,
    value: TransportStatus.AVAILABLE,
    callback: TransportStatus.AVAILABLE,
  },
  {
    label: TransportStatus.BOOKED,
    value: TransportStatus.BOOKED,
    callback: TransportStatus.BOOKED,
  },
  {
    label: TransportStatus.EXPIRED,
    value: TransportStatus.EXPIRED,
    callback: TransportStatus.EXPIRED,
  },
  {
    label: TransportStatus.LOCKED,
    value: TransportStatus.LOCKED,
    callback: TransportStatus.LOCKED,
  },
]

export const freeriderUserFilterItems: FilterItem[] = [
  {
    label: 'invited',
    value: 'INVITED_ADMIN',
    callback: 'INVITED_ADMIN',
  },
  {
    label: UserStatus.PENDING,
    value: UserStatus.PENDING,
    callback: UserStatus.PENDING,
  },
  {
    label: UserStatus.ACTIVATED,
    value: UserStatus.ACTIVATED,
    callback: UserStatus.ACTIVATED,
  },
  {
    label: UserStatus.WARNED,
    value: UserStatus.WARNED,
    callback: UserStatus.WARNED,
  },
  {
    label: UserStatus.SUSPENDED,
    value: UserStatus.SUSPENDED,
    callback: UserStatus.SUSPENDED,
  },
]

export const adminUserFilterItems: FilterItem[] = [
  {
    label: AdminAccessRole.ALL_ACCESS,
    value: AdminAccessRole.SUPER,
    callback: AdminAccessRole.SUPER,
  },
  {
    label: AdminAccessRole.TRANSPORT,
    value: AdminAccessRole.TRANSPORT,
    callback: AdminAccessRole.TRANSPORT,
  },
]

export const reservationFilterItems: FilterItem[] = [
  {
    label: ReservationStatus.BOOKED,
    value: ReservationStatus.CREATED,
    callback: ReservationStatus.CREATED,
  },
  {
    label: ReservationStatus.BOOKED_PASSED,
    value: ReservationStatus.PASSED,
    callback: ReservationStatus.PASSED,
  },
  {
    label: ReservationStatus.CANCELLED,
    value: ReservationStatus.CANCELLED,
    callback: ReservationStatus.CANCELLED,
  },
]

export const stationFilterItems: FilterItem[] = [
  { label: COUNTRY.NORWAY, value: COUNTRYCODE.NORWAY, callback: COUNTRYCODE.NORWAY },
  { label: COUNTRY.SWEDEN, value: COUNTRYCODE.SWEDEN, callback: COUNTRYCODE.SWEDEN },
]
