import { Typography } from '@mui/material'

import { TableLoadingState } from '../../types/table.types'

type Props = {
  loadingState: TableLoadingState
}

export default function LoadingStateMessage({ loadingState }: Props) {
  return (
    <>
      {loadingState === 'failed' && (
        <Typography style={{ margin: '24px', textAlign: 'center' }}>Something went wrong</Typography>
      )}
      {(loadingState === 'idle' || loadingState === 'pending') && (
        <Typography style={{ margin: '24px', textAlign: 'center' }}>Loading...</Typography>
      )}
    </>
  )
}
