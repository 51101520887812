import { Box, styled } from '@mui/material'

export const Card = styled(Box)(({ theme }) => ({
  fontWeight: '700',
  color: '#FFFFFF',
  fontSize: '14px',
  lineHeight: '24px',
  padding: theme.spacing(2),
  textAlign: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#e3f2fd',
  },
}))

export const DivStyledAsButton = styled('div')(({ theme }) => ({
  backgroundColor: '#3A81B3',
  color: '#FFFFFF',
  borderRadius: '4px',
  padding: theme.spacing(0.75, 2, 0.75, 2),
  margin: '32px auto 16px',
  width: 'fit-content',
  textTransform: 'uppercase',
}))

export const IconWrapper = styled('div')(() => ({
  width: 'auto',
  height: '48px',
  '& svg': { width: '48px', height: '48px' },
}))
