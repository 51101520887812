import { Box, Paper } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { TableColumn } from 'react-data-table-component'
import { useSelector } from 'react-redux'

import { reservationFilterItems } from '../../models/FilterItems'
import reservationColumns from '../../models/ReservationColumns'
import {
  fetchReservations,
  setReservationsColumnOrder,
  setReservationsHiddenColumns,
} from '../../store/slices/reservationsSlice'
import { RootState, useAppDispatch } from '../../store/store'
import { Reservation, ReservationColumnNames } from '../../types/Reservation'
import { AnyColumn, ColumnOrder } from '../../types/table.types'
import TableSearchField from '../table/components/TableSearchField'
import TableToolBar from '../table/components/TableToolBar'
import LoadingStateMessage from '../table/LoadingStateMessage'
import Table from '../table/Table'
import { checkIfColumnsAreEqual, getColumn, getColumnNames, getReOrderedColumns } from '../table/utils/columnHelpers'

// FIX - CJ-2901 - double check column names
const columnMap: AnyColumn = {
  [ReservationColumnNames.RES_NO]: 'reservationId',
  [ReservationColumnNames.FROM]: 'pickupLocation',
  [ReservationColumnNames.PICKUP_TIME]: 'pickupDate',
  [ReservationColumnNames.CUSTOMER]: 'firstName',
  [ReservationColumnNames.LICENCE_NO]: 'licenseNumber',
  [ReservationColumnNames.STATUS]: 'status',
  [ReservationColumnNames.RETURN_TIME]: 'returnDate',
}

export default function ReservationsTable() {
  const appDispatch = useAppDispatch()

  const {
    loadingReservations,
    reservations,
    tableSettings: settings,
  } = useSelector((state: RootState) => state.reservations)

  const tableData = useMemo(() => reservations, [reservations])
  const tableSettings = useMemo(() => settings, [settings])
  const columnNames = getColumnNames(reservationColumns)
  const reOrderedColumns = useMemo(
    () => getReOrderedColumns(reservationColumns, tableSettings.columnOrder),
    [tableSettings.columnOrder],
  )

  useEffect(() => {
    if (!tableData) {
      appDispatch(fetchReservations(tableSettings))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeSearchValue = (value: string) => {
    if (value !== tableSettings.freeText) {
      appDispatch(fetchReservations({ ...tableSettings, freeText: value }))
    }
  }

  const handleFilterChange = (value: string) => {
    if (value !== tableSettings.statusFilter) {
      appDispatch(fetchReservations({ ...tableSettings, statusFilter: value }))
    }
  }

  const handlePageChange = (newPage: number) => {
    if (newPage !== tableSettings.page) {
      appDispatch(fetchReservations({ ...tableSettings, page: newPage }))
    }
  }

  const handlePageSizeChange = (newPageSize: number, newPage: number) => {
    if (newPageSize !== tableSettings.pageSize) {
      appDispatch(fetchReservations({ ...tableSettings, page: newPage, pageSize: newPageSize }))
    }
  }

  const handleSort = (newColumn: TableColumn<Reservation>, newDirection: ColumnOrder) => {
    const column = getColumn(newColumn.name, columnMap)
    if ((column && column !== tableSettings.sortBy) || newDirection !== tableSettings.sortDirection) {
      appDispatch(
        fetchReservations({
          ...tableSettings,
          sortBy: column || tableSettings.sortBy,
          sortDirection: newDirection,
        }),
      )
    }
  }

  const handleColumnOrderChange = (order: TableColumn<Reservation>[]) => {
    const newOrder: string[] = []
    order.map((columnName: AnyColumn) => newOrder.push(columnName.name))
    appDispatch(setReservationsColumnOrder(newOrder))
  }

  const handleHideColumns = (columns: string[]) => {
    if (!checkIfColumnsAreEqual(columns, tableSettings.hiddenColumns)) {
      appDispatch(setReservationsHiddenColumns(columns))
    }
  }

  return (
    <Paper>
      <Box display="flex" justifyContent="space-between" padding={2}>
        <TableSearchField initialValue={tableSettings.freeText} onChange={changeSearchValue} />
        <TableToolBar
          columnNames={columnNames}
          filterItems={reservationFilterItems}
          filter={tableSettings.statusFilter ?? ''}
          hiddenColumns={tableSettings.hiddenColumns}
          onFilterChange={handleFilterChange}
          onCheckedColumnsChange={handleHideColumns}
        />
      </Box>
      {!tableData ? (
        <LoadingStateMessage loadingState={loadingReservations} />
      ) : (
        <Table<Reservation>
          data={tableData}
          columns={reOrderedColumns}
          columnsToHide={tableSettings.hiddenColumns}
          sortServer
          defaultSortFieldId={tableSettings.sortBy}
          defaultSortAsc={tableSettings.sortDirection === 'asc'}
          paginationServer
          paginationDefaultPage={tableSettings.page}
          paginationPerPage={tableSettings.pageSize}
          paginationTotalRows={tableSettings.totalSize}
          disabled={loadingReservations === 'pending'}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePageSizeChange}
          onSort={handleSort}
          onColumnOrderChange={handleColumnOrderChange}
        />
      )}
    </Paper>
  )
}
